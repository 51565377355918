import React from 'react'
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import {API} from 'aws-amplify';

class RegionSelect extends React.Component{
    constructor(props){
        super(props);
        this.handleChange = this.handleChange.bind(this);

        this.state = {data:[]};
    }

    handleChange(e){
        this.props.handleChange(e);
    }

    componentDidMount() {
        if(typeof this.props.queryvalue === 'undefined' || this.props.queryvalue === ""){
            return;
        }

        this.props.setLoading(true);
        let currentComponent = this;

        API.get('newregionsapi', '/newregions/'+this.props.queryvalue).then(data => {
            currentComponent.setState({data: data[0].subregions});
            this.props.setLoading(false);
        }).catch((err) => {
            console.log(" ERROR "+err);
            this.props.setLoading(false);
        });
    }

    render(){
        const items = (typeof this.state.data !== 'undefined') ? 
        this.state.data.map((item) => {
            return (
                <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
            )
        }) : [];
        
        return (
            <FormControl required >
                    <InputLabel id={`${this.props.id}+label`} >{this.props.label}</InputLabel>
                    <Select id={this.props.id} name={this.props.id} readOnly={this.props.readOnly}
                    value={this.props.value} onChange={this.handleChange}  >
                        {items}
                    </Select>
            </FormControl>
        )
    }
}

export default RegionSelect;