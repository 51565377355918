import React from 'react';
import SimplePanel from './SimplePanel'
import { FixedSizeList } from 'react-window';
import FullList from '@material-ui/icons/UnfoldMore';

class ListPanel extends React.Component{
    render (){
        const Row = ({ index, style }) => {
            return (
            <div className={index % 2 ? 'ListItemOdd' : 'ListItemEven'} style={style} >
                <div style={{padding:'5px', fontStyle: 'italic', fontSize: 'large', width:'50%', textAlign:'left'}}>
                    {toScientificName(this.props.list[index])}
                </div> 
            </div>
        )};

        const buttons = <div className="listpanelbuttonbar">
        { typeof this.props.showFullList !== 'undefined' ? 
            <FullList onClick={this.props.showFullList} 
            style={{
                color: 'gray', 
                margin: '5px',
                cursor:'pointer'
            }}/>
        : null }
        </div>

        return (
            <SimplePanel 
                title={this.props.title} 
                buttons={buttons}
                addStyle={{borderTop: '2px solid '+this.props.fillcolor}}
            >
                <FixedSizeList height={100} itemSize={25} itemCount={this.props.list.length} className="List">
                    {Row}
                </FixedSizeList>
            </SimplePanel>
        )
    }
}

function toScientificName(s){
    if(s.length > 1){
        return s.substring(0,1).toUpperCase() + s.substring(1).toLowerCase();
    }
    else{
        return s;
    }
}

export default ListPanel;