import React from 'react'
import Draggable from 'react-draggable';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import RegionSelect from './RegionSelect';
import {API} from 'aws-amplify';

function PaperComponent(props) {
    return (
      <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
}

function RunFramework(props){
    const selectors = props.selectors.split('-');

    const [state, setState] = React.useState({
        year: parseInt(selectors[0]),
        ecosystem: selectors[1],
        state: selectors.slice(2,4).join("-"),
        loading: false,
        error: "",
        success: ""
    });

    function handleCancel(){
        props.close();
    }

    function handleSubmit(){
        setState({...state, loading: true});
        if(!validate()){
            setState({...state, 
                error:"Input missing for mandatory fields. Please review.",
                success: "",
                loading: false});
            return;
        }
        else{
            let myInit = {
                body: state,
                headers: {} 
            }

            API.post('frameworkapi', '/framework', myInit).then(data => {
                //console.log(data);
                setState({...state, error:"", success: data.success, loading: false});
            }).catch((err) => {
                console.log(" ERROR "+err);
                setState({...state, error:"Execution failed", loading: false});
            });
        }
    }

    function validate(){
        let value = null;
        for(let key of Object.keys(state)){
            if(!['success', 'error'].includes(key)){
                value = state[key];
                if(value === null || value === ""){
                    return false;
                }
            }            
        }
        return true;
    }

    function handleChange(e){
        setState({
            ...state,
            [e.target.name] : e.target.value
        }) 
    }

    function setLoading(boolean){
        setState({...state, loading: boolean});
    }

    return (
        <div>
        <Dialog open={props.open} disableBackdropClick={true}
            onClose={handleCancel} PaperComponent={PaperComponent} >
            <DialogTitle 
                style={{ 
                    cursor: 'move', 
                    borderBottom: '1px groove lightgray' 
                }} id="draggable-dialog-title"><b>Data analysis framework</b>
            </DialogTitle>
            <DialogContent>
            <Fade in={state.loading} unmountOnExit style={{
                transitionDelay: state.loading ? '800ms' : '0ms',
            }}>
                <LinearProgress />
            </Fade>
            <div style={{color:'green'}}>{state.success}</div>
            <DialogContentText>
                {state.error}
            </DialogContentText> 
            <form> 
            <div style={{display:'flex', flexDirection:'column', padding:'20px'}} >
            <FormControl required >
                <InputLabel id="year-label">Year</InputLabel>
                <Select id="year" value={state.year} onChange={handleChange} readOnly>
                    <MenuItem value={2019}>2019</MenuItem>
                </Select>
            </FormControl>
            <FormControl required >
                <InputLabel id="eco-label">Ecosystem Type</InputLabel>
                <Select id="ecosystem" name="ecosystem" value={state.ecosystem} onChange={handleChange} readOnly>
                    <MenuItem value="man">Mangroves</MenuItem>
                    <MenuItem value="cor" >Corals</MenuItem>
                    <MenuItem value="sea">Seagrass</MenuItem>
                    <MenuItem value="sal">Saltmarshes</MenuItem>
                </Select>
            </FormControl>
            
            <RegionSelect 
                required={true} handleChange={handleChange} key="state"
                id="state" label="State" value={state.state} queryvalue="in"
                setLoading={setLoading} readOnly={true}
            />
            </div>
            </form>
            </DialogContent>
            <DialogActions>
                <button className="bbutton" onClick={handleCancel} > Close </button>
                <button 
                    className="bbutton" 
                    onClick={handleSubmit} 
                    style={{
                        color:'white', 
                        backgroundColor:'rgb(74, 119, 229)',
                        width:'auto'
                    }}
                > Execute </button>
            </DialogActions>
        </Dialog>
        </div>
    )
}

export default RunFramework;