
import {API} from 'aws-amplify';

export const getEco  = (params) => {
    return (dispatch, getState) => {
        API.get('ecosystemsapi', '/ecosystems/'+params.id)
        .then(data => {
            //console.log(JSON.stringify(data));
            dispatch({type : "GET_DATA", data})
        })
        .catch((err) => {
            console.log(" ERROR "+err);
                console.log("Will retry after 5 seconds")
                setTimeout(function(){ 
                    console.log("Retrying")
                    API.get('ecosystemsapi', '/ecosystems/'+params.id)
                    .then(data => {
                        dispatch({type : "GET_DATA", data})
                    })
                    .catch((err) => {
                        console.log(" ERROR in retry "+err);
                        dispatch({type:'GET_DATA_ERROR', err})
                    })
                }, 5000);
        })
    }
}