import React from 'react';
import SimplePanel from './SimplePanel';
import AddIcon from '@material-ui/icons/AddCircleOutline';


class NDADetail extends React.Component {
    handleClick(datasetid){
        this.props.selectNDA(datasetid);
    }

    render(){
        const editPermission = this.props.loggedIn.signInUserSession !== undefined ? 
        this.props.loggedIn.signInUserSession.accessToken.payload["cognito:groups"].includes("dataadmin")
        : false;

        const buttonbar = [];
        if(editPermission) {
            buttonbar.push(<AddIcon key="add" onClick={this.showForm} style={{color: 'rgb(14,81,127)', cursor: "pointer",paddingLeft: '10px', marginBottom:'-5px'}} /> );
        }

        const datasets = [
                            "Ports", "Dams", "Thermal Power Plants", "Coastal State Boundaries",
                            "Coastal District Boundaries", "Rivers", "Demography/Census",
                            "Coastal Alignment", "Land Use/Land Cover", "Geomorphology",
                            "Hazard line", "Erosion/Accretion", "Sediment Cell", "CVCA", 
                            "Mangroves", "Coral Reef", "Salt Marshes", "Seagrass"
                        ];

        const datasetList = datasets.map((dataset, i) => {
            return (
                <button id={i} key={dataset} className="bbutton" 
                style={this.props.selectedNDA !== undefined && this.props.selectedNDA !== null && this.props.selectedNDA === dataset ?
                    {color: 'white', backgroundColor: 'rgb(14,81,127)', border:'1px solid rgb(14,81,127)', fontWeight: 'bold' } :
                    {color: 'rgb(14,81,127)', backgroundColor: 'white', border:'1px solid rgb(14,81,127)', fontWeight: 'bold' }
                }

                onClick={() => this.handleClick(dataset)} >{dataset}</button>
            )
        });

        return (
           <div className="ecosystemdetail" key="ecosystemdetail" >
                    <div className="stickyheader" > 
                        <SimplePanel title="Planning Data" 
                        addStyle={{width:'98%'}}
                        buttons={buttonbar}
                        >
                            <div className="regionpanelbuttonbar" style={{alignItems:'center'}}>
                                {datasetList}
                            </div>
                        </SimplePanel>
                    </div>
            </div>
        );
    }
}

export default NDADetail;