import React from 'react';
import {Component} from 'react';
import ReactMapGL,{Source, Layer, Marker,  NavigationControl} from 'react-map-gl';
import { connect } from 'react-redux';
import { getEco } from '../store/Actions/GetEcoAction'
import { getPatch } from '../store/Actions/GetPatchAction'
import { getRA } from '../store/Actions/GetRAAction';
import code from '../colorcoding.png'
import Draggable from 'react-draggable';
import {Storage} from 'aws-amplify';
import {getColor} from './Utils';
import {isEmpty} from './Utils';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import Max from '@material-ui/icons/FullscreenOutlined';
import Min from '@material-ui/icons/FullscreenExitOutlined';

function StylePickerMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const mapStyles = [
    {name: 'Light', style: "mapbox://styles/mapbox/light-v10?optimize=true"},
    {name: 'Dark', style: "mapbox://styles/mapbox/dark-v10?optimize=true"},
    {name: 'Satellite', style: "mapbox://styles/mapbox/satellite-v9?optimize=true"},
    {name: 'Streets', style: "mapbox://styles/mapbox/streets-v11?optimize=true"},
    {name: "Outdoors", style: "mapbox://styles/mapbox/outdoors-v11?optimize=true"},
    {name: "Day Navigation", style: "mapbox://styles/mapbox/navigation-guidance-day-v4?optimize=true"},
    {name: "Night Navigation", style: "mapbox://styles/mapbox/navigation-guidance-night-v4?optimize=true"},
    {name: "Day Traffic", style: "mapbox://styles/mapbox/navigation-preview-day-v4?optimize=true"},
    {name: "Night Traffic", style: "mapbox://styles/mapbox/navigation-preview-night-v4?optimize=true"},
    {name: "Satellite+Streets", style:"mapbox://styles/mapbox/satellite-streets-v11?optimize=true"}

  ];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (mapStyle) => {
    props.setMapStyle(mapStyle);
    setAnchorEl(null);
  };

  const mapStyleMenu = mapStyles.reverse().map((mapStyle) => {
    return <MenuItem key={mapStyle.name} onClick={() => handleClose(mapStyle)}>{mapStyle.name}</MenuItem>   
  });

  return (
    <div style={{bottom:15, right:5, position: 'absolute'}}>
      <button onClick={handleClick} className="bbutton" style={{border: '1px solid black'}}>
        Basemap
      </button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {mapStyleMenu}
      </Menu>
    </div>
  );
}

const navStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  padding: '10px'
};

class Map extends Component {
  constructor(props){
    super(props);
    this.state = { sources: [], cvcasource : null, viewport: this.props.viewport };
    
    this.onMapLoad = this.onMapLoad.bind(this);
    this.onCVCAMapLoad  = this.onCVCAMapLoad.bind(this);
    this.handleMapClick = this.handleMapClick.bind(this);
  }

  onMapLoad(event) {
     ["admin-0-boundary","admin-1-boundary", "admin-0-boundary-disputed", "admin-1-boundary-bg", "admin-0-boundary-bg"]
      .forEach(function(adminLayer) {
      event.target.setFilter(adminLayer, ["match",["get", "worldview"],["all", "IN"],true,false]);
    }); 
    //this.props.loading(false);
    this.props.backdrop(false);
  }

  _updateViewport = viewport => {
    this.setState({viewport});
  };

  onCVCAMapLoad(event) {
    //console.log("inside onCVCAMapLoad ");
    this.props.loading(false);
 }

  handleClick(regionid){
    if(this.props.level === 4){
      this.props.loading(true);
      //this.props.showFullScreen("none");
      this.props.getPatch({id : regionid});
    }
    else{
      this.props.loading(true);
      //this.props.showFullScreen("none");
      this.props.getEco({id : regionid});
    }
  }

  handleClickRA(selectedRA){
    const ra = this.props.cvca.framework.find(f => f.id === selectedRA.id);
    this.props.selectRA(ra);
    this.props.loading(true);
  }
 
  handleMapClick = event => { 
    const feature = event.features[0];
    if(typeof feature === 'undefined'){
      return;
    }

    const regionTags = ["COUNTRY", "STATE_NAME", "DISTRICT", "TALUK_NAME"];
    const currentRegion = feature.properties[regionTags[this.props.level]];
    if(typeof currentRegion === 'undefined'){
      return;
    }

    this.props.loading(true);

    const currentRegionObject = ((typeof this.props.ecoProfile !== 'undefined' && typeof this.props.ecoProfile.regions !== 'undefined' )) ? 
      this.props.ecoProfile.regions.find((region) => {
        if(region.name === currentRegion){
          return region;
        }
        return null;
      }) : undefined;
    if(typeof currentRegionObject === 'undefined'){
      return;
    }

    this.props.getEco({id : (currentRegionObject.id)});
  }

  _getCursor = ({isHovering, isDragging}) => {
    return 'pointer';
  };

  componentDidMount(){
    if(this.props.selectedTab !== null && this.props.selectedTab.id === 'esa'){
      if(this.props.ecoProfile.level !== undefined && this.props.ecoProfile.level >= 4){
        this.listFiles();
      }
    }
    else if(this.props.selectedTab !== null && this.props.selectedTab.id === 'cvca'){
      if(this.props.cvca !== undefined && this.props.cvca !== null && !isEmpty(this.props.cvca) ){
        Storage.get('cvca_geo/'+this.props.cvca.year+'/'+this.props.cvca.id+'.geojson', {level:'public'})
        .then(result => {
          this.setState({...this.state, cvcasource: result})
        })
        .catch(err => console.log(err));
      }
    }
    else if(this.props.selectedTab !== null && this.props.selectedTab.id === 'nda'){

    }
  }

  listFiles = async () => {
    const path = this.getS3FolderPath(this.props.ecoProfile.id);
    if(path.indexOf(".geojson") === path.length - 8){
      Storage.get(path, {level:'public'})
      .then(result => {
        this.setState({...this.state, sources: [result] })
      })
      .catch(err => console.log(err));
    }
    else{
      const files = await Storage.list(this.getS3FolderPath(this.props.ecoProfile.id));
      let signedFiles = files.map(f => Storage.get(f.key))
      signedFiles = await Promise.all(signedFiles)
      this.setState({ ...this.state, sources: signedFiles })
    }
  }

  render(){
    if(this.props.selectedTab !== null && this.props.selectedTab.id === 'esa'){
      return this.renderESA();
    }
    else if(this.props.selectedTab !== null && this.props.selectedTab.id === 'cvca'){
      return this.renderCVCA();
    }
    else if(this.props.selectedTab !== null && this.props.selectedTab.id === 'nda'){
      return this.renderNDA();
    }
    else{
      return (<ReactMapGL 
            {...this.state.viewport}
            mapboxApiAccessToken="pk.eyJ1IjoicmFhbWFtIiwiYSI6ImNrMzhxNGhzeDBjYjUzZGx1MGt1ZnNyZ2EifQ.OOZznHuau46k8q_BxIC8Hw"
            mapStyle={this.props.mapStyle.style}
            //mapStyle="mapbox://styles/mapbox/outdoors-v11"
            //mapStyle="mapbox://styles/mapbox/satellite-v9"
            //mapStyle="mapbox://styles/mapbox/dark-v10"
            //mapStyle="mapbox://styles/mapbox/streets-v11"
            onClick={this.handleMapClick}
            onLoad={this.onMapLoad}
            getCursor={this._getCursor}
            onViewportChange={this.onViewportChange}
        >
            <StylePickerMenu setMapStyle={this.props.setMapStyle} />
        </ReactMapGL>);
    }
  }

  renderNDA() {
    const marker = (this.props.selectedNDA !== undefined && this.props.selectedNDA !== null) ?
                  (
                    <Marker 
                      key="dummytext" 
                      longitude={72.208016} 
                      latitude={25.426996}
                      captureDrag={false}
                      captureDoubleClick={false}
                    >
                      <div style={{
                            cursor:'pointer', 
                            color:'rgb(14,81,127)', 
                            fontSize:'xxx-large', 
                            fontWeight:'bold'
                        }} 
                      >
                        <span>Coming soon</span>
                      </div> 
                  </Marker>) : null;

    return (
      <ReactMapGL 
          {...this.state.viewport}  
          key="esamap"
          mapboxApiAccessToken="pk.eyJ1IjoicmFhbWFtIiwiYSI6ImNrMzhxNGhzeDBjYjUzZGx1MGt1ZnNyZ2EifQ.OOZznHuau46k8q_BxIC8Hw"
          mapStyle={this.props.mapStyle.style}
          //mapStyle="mapbox://styles/mapbox/outdoors-v11"
          //mapStyle="mapbox://styles/mapbox/satellite-v9"
          //mapStyle="mapbox://styles/mapbox/dark-v10"
          //mapStyle="mapbox://styles/mapbox/streets-v11"
          onClick={this.handleMapClick}
          onLoad={this.onMapLoad}
          getCursor={this._getCursor}
          onViewportChange={this.onViewportChange}
      >
        {marker}
      </ReactMapGL>
    )
  }

  renderESA() {
    let layers = null;
    const regionTags = ["COUNTRY", "STATE_NAME", "DISTRICT", "TALUK_NAME"];

    if( this.props.level < 4){
      layers = (this.props.ecoProfile !== undefined && this.props.ecoProfile.regions !== undefined ) ?
                this.props.ecoProfile.regions.map((region) => {
                  return (
                      <Layer 
                        id={region.id} 
                        key={region.id} 
                        type='fill' 
                        paint={{'fill-color':this.props.fillcolor,'fill-opacity': 0.8}} 
                        filter={['==', regionTags[this.props.level], region.name]} 
                      />
                    )
                }) : null;  
    }
    else{
      const path = this.getS3FolderPath(this.props.ecoProfile.id);
      let filteredList = null;
      if(path.indexOf(".geojson") === path.length - 8){
        filteredList =  (typeof this.props.ecoProfile !== 'undefined' && typeof this.props.ecoProfile.patches !== 'undefined' ) ?
        this.props.ecoProfile.patches.filter(patch => patch.id === this.props.ecoProfile.id) : [];
      
        layers =  filteredList.map((patch) => {
          let stl = {'fill-color':getColor(patch.value),'fill-opacity': 0.8};
                    return (
                        <Layer 
                          id={patch.id} 
                          key={patch.id} 
                          type='fill' 
                          paint={stl} 
                        />
                      )
                  }); 
      
      }
    }
   
    let markers = null;
   let filteredList = null;

    if( this.props.level < 4){
      filteredList = (typeof this.props.ecoProfile !== 'undefined' && typeof this.props.ecoProfile.regions !== 'undefined' ) ?
                    this.props.ecoProfile.regions : [];

      markers = filteredList.map((region) => {
        return (
          <Marker
          key={region.id}
          longitude={region.viewport.longitude}
          latitude={region.viewport.latitude}
          captureDrag={false}
          captureDoubleClick={false}
        >
          <div  
          style={{cursor:'pointer', backgroundColor:'rgb(0,0,0,0.5)', color:'white', 
              borderRadius:'20px', padding: '5px', fontSize:'medium', marginRight:'20px' }} 
          onClick={() => this.handleClick(region.id)}>
            <span>{region.name}</span>
          </div> 
        </Marker>
        )
      });   
    }
    else{
      const path = this.getS3FolderPath(this.props.ecoProfile.id);
      if(path.indexOf(".geojson") === path.length - 8){
       /* filteredList = (typeof this.props.ecoProfile !== 'undefined' && typeof this.props.ecoProfile.regions !== 'undefined' ) ?
                       this.props.ecoProfile.regions.filter(region => region.id === this.props.ecoProfile.id) : []; */
        markers = null;
     }
     else{
       filteredList = (typeof this.props.ecoProfile !== 'undefined' && typeof this.props.ecoProfile.regions !== 'undefined' ) ?
                       this.props.ecoProfile.regions : []; 

      markers = filteredList.map((region) => {
      return (
        <Marker
        key={region.id}
        longitude={region.viewport.longitude}
        latitude={region.viewport.latitude}
        captureDrag={false}
        captureDoubleClick={false}
      >
        <div  
        style={{cursor:'pointer', backgroundColor:'rgb(0,0,0,0.5)', color:'white', 
            borderRadius:'20px', padding: '5px', fontSize:'small', marginRight:'20px' }} 
        onClick={() => this.handleClick(region.id)}>
          <span>{region.name}</span>
        </div> 
      </Marker>
      )
  });   
     }
    }

  
   let source = null;
   //let viewport = {...this.state.viewport} ;
   if( this.props.level < 4){
      source = (<Source 
                  id="state" 
                  type="geojson" data="https://esa-store151913-production.s3.amazonaws.com/geojson/coastal.geojson">
                    { layers}
                </Source>);
   }
   else{
    //viewport =  {...this.state.viewport, bearing: 270} ;
    //console.log(this.state.sources.length)
    const path = this.getS3FolderPath(this.props.ecoProfile.id);
    if(path.indexOf(".geojson") === path.length - 8){
      source = this.state.sources.map((src,i) => {
        return (<Source id="patch" key={"patch"+i} type="geojson" data={src}>{layers}</Source>)
      })
    }
    else{
      source = this.state.sources.map((src,i) => {
        return (
          <Source id={"patch"+i} key={"patch"+i} type="geojson" data={src}>
            {this.props.ecoProfile !== undefined && 
            this.props.ecoProfile.patches !== undefined && 
            this.props.ecoProfile.patches.length > 0 &&
            this.props.ecoProfile.patches[i] !== undefined ? 
            <Layer 
              id={this.props.ecoProfile.patches[i].id}
              key={this.props.ecoProfile.patches[i].id} 
              type='fill' 
              paint={{'fill-color':getColor(this.props.ecoProfile.patches[i].value),'fill-opacity': 0.8}} 
            />
            : null }
          </Source>
        )
      })
    }

   }

   //console.log("full screen "+this.props.fullScreen);

   const max_min = this.props.fullScreen === "Map" ?
   <Min style={{
           color: this.props.fillcolor,
           cursor: "pointer",
           paddingLeft: '10px',
       }} 
   onClick={() => this.props.showFullScreen('none')} /> :
   <Max style={{
       color: this.props.fillcolor,
       cursor: "pointer",
       paddingLeft: '10px',
   }} 
   onClick={() => this.props.showFullScreen('Map')} />
 

    return (
        <ReactMapGL 
            {...this.state.viewport}  
            key="esamap"
            mapboxApiAccessToken="pk.eyJ1IjoicmFhbWFtIiwiYSI6ImNrMzhxNGhzeDBjYjUzZGx1MGt1ZnNyZ2EifQ.OOZznHuau46k8q_BxIC8Hw"
            mapStyle={this.props.mapStyle.style}
            //mapStyle="mapbox://styles/mapbox/outdoors-v11"
            //mapStyle="mapbox://styles/mapbox/satellite-v9"
            //mapStyle="mapbox://styles/mapbox/dark-v10"
            //mapStyle="mapbox://styles/mapbox/streets-v11"
            onClick={this.handleMapClick}
            onLoad={this.onMapLoad}
            getCursor={this._getCursor}
            onViewportChange={this._updateViewport}
        >
          {source}
          
          {markers}
          { this.props.level >= 2 ? 
          [<Draggable key="colorcoding">
          <div style={{bottom:20, left:10, position: 'absolute', cursor:'move'}}>
            <img src={code} alt="color coding" width="100%" height="100%" />
          </div>
          </Draggable>,
          <div key="minmaxbtn" style={{top:5, right:5, position: 'absolute'}}>{max_min}</div>]
          : null }
          <div style={navStyle}>
            <NavigationControl/>
          </div>
          <StylePickerMenu setMapStyle={this.props.setMapStyle} />
        </ReactMapGL>
    );
  }

  getS3FolderPath(str) {
    var tokens = str.split("-");
    if(tokens.length === 6){
        return 'geo/' + tokens[0] + '/' + tokens[1] + '/' + tokens[2] + '-' + tokens[3] + '/' + 
                tokens[2] + '-' + tokens[3] + '-' + tokens[4] + '/' +  
                tokens[2] + '-' + tokens[3] + '-' + tokens[4] + '-' + tokens[5] + '/';
    }
    else {
        return 'geo/' + tokens[0] + '/' + tokens[1] + '/' + tokens[2] + '-' + tokens[3] + '/' + 
                tokens[2] + '-' + tokens[3] + '-' + tokens[4] + '/' + 
                tokens[2] + '-' + tokens[3] + '-' + tokens[4] + '-' + tokens[5] + '/' + 
                tokens.slice(6).join('-') + '.geojson';
    }
  }

  renderCVCA(){
    if(this.props.cvca === undefined || this.props.cvca === null || isEmpty(this.props.cvca) ){
      const features = (this.props.cvcas !== undefined && this.props.cvcas !== null) ? 
      this.props.cvcas.map((cvca) => {
        return { 'type': 'Feature', 'geometry': {'type': 'Point', 'coordinates': [cvca.viewport.longitude, cvca.viewport.latitude]}}
      }) : [];

      var source = {'type': 'FeatureCollection', 'features': features};
      const markers = (this.props.cvcas !== undefined && this.props.cvcas !== null) ? 
      this.props.cvcas.map((cvca) => {
      return (
                <Marker 
                  key={cvca.id} 
                  longitude={cvca.viewport.longitude} 
                  latitude={cvca.viewport.latitude}
                  captureDrag={false}
                  captureDoubleClick={false}
                >
                  <div style={{
                        cursor:'pointer', 
                        color:'rgb(201,108,56)', 
                        fontSize:'medium', 
                        marginLeft:'-40px',
                        marginTop:'5px',
                        fontWeight:'bold'
                    }} 
                    onClick={() => this.props.getRA({yearofstudy: 2019, cvca_id: cvca.id, cvca_name: cvca.name})}
                  >
                    <span>{cvca.name}</span>
                  </div> 
                </Marker>
        ) 
      }) : null; 

      return (
              <ReactMapGL 
                {...this.state.viewport}
                key="cvcamap"
                mapboxApiAccessToken="pk.eyJ1IjoicmFhbWFtIiwiYSI6ImNrMzhxNGhzeDBjYjUzZGx1MGt1ZnNyZ2EifQ.OOZznHuau46k8q_BxIC8Hw"
                mapStyle={this.props.mapStyle.style}
                onLoad={this.onCVCAMapLoad}
                onViewportChange={this._updateViewport}
              >
              <div style={navStyle}>
                <NavigationControl/>
              </div>
              <Source id="point" type="geojson" data={source}>
                <Layer 
                    id="circle" 
                    key="circle" 
                    type='circle' 
                    paint={{'circle-radius': 10,'circle-color':'rgb(201,108,56)'}} 
              />
              </Source>
                {markers}
                <StylePickerMenu setMapStyle={this.props.setMapStyle} />
              </ReactMapGL>     
        );           
    } 
    else{
      const selectedRA = this.props.selectedRA !== undefined && this.props.selectedRA !== null && 
                          this.props.cvca.resourceareas !== undefined && this.props.cvca.resourceareas !== null ?
                          this.props.cvca.resourceareas.find(resourcearea => resourcearea.id === this.props.selectedRA.id) :
                          null;
     
      const layer =  (this.props.cvca !== undefined && this.props.cvca !== null ) ?
                     (this.props.selectedRA !== undefined && this.props.selectedRA !== null ) ?
                      <Layer 
                        id={this.props.cvca.id} 
                        key={this.props.cvca.id} 
                        type='fill' 
                        paint={{'fill-color': getColor(this.props.selectedRA.cbrm_index),'fill-opacity': 0.8}} 
                        filter={['==', 'name', this.props.selectedRA.id]}
                      /> :
                      this.props.cvca.resourceareas.map((resourcearea) => {
                        return (
                            <Layer 
                              id={resourcearea.id}  
                              key={resourcearea.id} 
                              type='fill' 
                              paint={{'fill-color':getColor(resourcearea.cbrm),'fill-opacity': 0.8}} 
                              filter={['==', 'name', resourcearea.id]}
                            />
                          )
                      })
                      : null;  
    
          const markers = (this.props.cvca !== undefined && this.props.cvca !== null && 
                          this.props.cvca.resourceareas !== undefined && 
                          this.props.cvca.resourceareas !== null) ? 
                          this.props.cvca.resourceareas.map((ra) => {
                            return (
                              <Marker 
                                key={ra.id} 
                                longitude={ra.viewport.longitude} 
                                latitude={ra.viewport.latitude}
                                captureDrag={false}
                                captureDoubleClick={false}
                              >
                                <div style={{
                                  cursor:'pointer', 
                                  backgroundColor:'rgb(0,0,0,0.5)', 
                                  color:'white', 
                                  borderRadius:'20px', 
                                  padding: '5px', 
                                  fontSize:'medium', 
                                  marginRight:'20px' }} 
                                  onClick={() => this.handleClickRA(ra)}
                                >
                                  <span>{ra.id.split('-')[0]}</span>
                                </div> 
                              </Marker>
                            ) 
                          }) : null; 
    
      return (
        <ReactMapGL 
            {...this.state.viewport}
            key="ramap"
            mapboxApiAccessToken="pk.eyJ1IjoicmFhbWFtIiwiYSI6ImNrMzhxNGhzeDBjYjUzZGx1MGt1ZnNyZ2EifQ.OOZznHuau46k8q_BxIC8Hw"
            mapStyle={this.props.mapStyle.style}
            onLoad={this.onCVCAMapLoad}
            getCursor={this._getCursor}
            onViewportChange={this._updateViewport}
        >
          <div style={navStyle}>
            <NavigationControl/>
          </div>
          <Draggable key="colorcoding">
          <div style={{bottom:20, left:10, position: 'absolute', cursor:'move'}}>
            <img src={code} alt="color coding" width="100%" height="100%" />
          </div>
          </Draggable>
          
          <Source id="cvca" key="cvca" type="geojson" data={this.state.cvcasource}>
            {layer}
          </Source>
          {selectedRA === null ? markers : null}
          <StylePickerMenu setMapStyle={this.props.setMapStyle} />
        </ReactMapGL> 
      )
    } 
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
          getEco: (params) => dispatch(getEco(params)),
          getPatch: (params) => dispatch(getPatch(params)),
          getRA: (params) => dispatch(getRA(params)),
      }
}    

const mapStateToProps = state => {
  return {
    ecoProfile: state.getEco.EcoProfile,
    cvcas: state.getCVCA.CVCAList,
    cvca: state.getRA.CVCA
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Map);