import React from 'react';
import SimplePanel from './SimplePanel'
import {Layer, Rect, Stage, Text, Circle, RegularPolygon} from 'react-konva';
import { connect } from 'react-redux';
import { getRA } from '../store/Actions/GetRAAction';
import {getColor} from './Utils';

class CVCAGovernance extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            w: window.innerWidth*0.6-20, 
            h: window.innerHeight*0.5-50,  
            regions : this.props.regions,
            cursor : 'default',
        };

        this.updateDimensions = this.updateDimensions.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
        this.onMouseOut = this.onMouseOut.bind(this);

        this.circleRefArray = new Array(this.state.regions.length);
        this.textRefArray = new Array(this.state.regions.length);
        for(let i=0; i<this.state.regions.length; i++){
            this.circleRefArray[i] = React.createRef();
            this.textRefArray[i] = React.createRef();
        }
    }

    updateDimensions() {
        this.setState({...this.state, 
            w: window.innerWidth*0.6-20, 
            h: window.innerHeight*0.5-50, 
            regions : this.props.regions,
            cursor : 'default'
        });

        for(var i=0; i<this.state.regions.length; i++){
            let patch = this.state.regions[i];
            if(isNaN(patch.resource_dependency) || isNaN(patch.governance_ability)){
                continue;
            }
            this.circleRefArray[i].to({
                x: patch.governance_ability * this.state.w / 100 ,
                y: (100 - patch.resource_dependency) * (this.state.h - 20) / 100 ,
                duration:1
            });
            this.textRefArray[i].to({
                x: (patch.governance_ability * this.state.w / 100) - 60,
                y: ((100 - patch.resource_dependency) * (this.state.h - 20) / 100) -6,
                duration:1
            });
        }
    };
 
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions();
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    onMouseOver(e){
        const cursor = typeof e.target.attrs.id != 'undefined' ? 'pointer' : 'default'
        this.setState({...this.state, cursor:cursor});
    }

    onMouseOut(e){
        this.setState({...this.state, cursor:'default'});
    }
    handleClick(e){
        
        if(typeof e.target.attrs.id !== 'undefined'){
            if(this.props.selectedRA === undefined || this.props.selectedRA === null || this.props.selectedRA !== e.target.attrs.id){
                this.props.loading(true);
            }
            this.props.selectRA(e.target.attrs.id);
        }
    }
    
    render(){
        const circles = typeof this.state.regions !== 'undefined' ?  
        this.state.regions.map((patch, i) => {
            return (
                [<Circle 
                    key={patch.id} id={patch}
                    radius={15} fill={getColor(patch.cbrm_index)}
                    x={0} y={this.state.h}
                    stroke={this.props.selectedRA !== null && this.props.selectedRA.id === patch.id ? 'rgb(201,108,56)' : 'white'}
                    zIndex={this.props.selectedRA !== null && patch.id === this.props.selectedRA.id ? 26 : 18 + i }
                    ref={ref => (this.circleRefArray[i] = ref)}
                />,
                <Text 
                    key={patch.id+'_title'}  id={patch}
                    text={patch.id.split('-')[0]} fontSize={14} 
                    fontFamily={['Abhaya Libre', 'serif']} 
                    x={0} y={this.state.h}
                    zIndex={this.props.selectedRA !== null && patch.id === this.props.selectedRA.id ? 27 : 19 + i}
                    align="center" width={120} height={5}
                    fill={'white'}
                    ref={ref => (this.textRefArray[i] = ref)}
                />,
                ]
            )
        }) : null;

        return (
                <SimplePanel 
                    title={this.props.name+": Decision Matrix"} 
                    addStyle={{
                        borderRadius:'0px',
                        }}
                >
                    <Stage 
                        className="matrix" 
                        width={this.state.w} 
                        height={this.state.h}
                        onClick={this.handleClick}
                        onMouseOver={this.onMouseOver}
                        onMouseOut={this.onMouseOut}
                        style={{cursor:this.state.cursor}}
                    >
                    
                        <Layer>
                           <Rect
                                x={0} y={20} 
                                width={20} 
                                height={this.state.h}
                                fillLinearGradientStartPoint= {{x: 0, y: 0}}
                                fillLinearGradientEndPoint= {{x: 0, y: this.state.h}}
                                fillLinearGradientColorStops= {[0, 'rgb(0, 109, 173)', 1, 'rgba(0, 109, 173,0.5)']}
                            /> 

                            <RegularPolygon
                                x={10} y={11} 
                                sides={3}
                                fill='rgb(0, 109, 173)'
                                stroke='rgb(0, 109, 173)'
                                radius={10} 
                            />

                            
                            <Rect
                                x={0} y={this.state.h-19} 
                                width={19} 
                                height={19}
                                fill='rgb(75,147,94)'
                                stroke='rgb(75,147,94)'
                            />

                            <Rect
                                x={20} y={this.state.h-20} 
                                width={this.state.w-40} 
                                height={20}
                                fillLinearGradientStartPoint= {{x: 0, y: this.state.h-20}}
                                fillLinearGradientEndPoint= {{x: this.state.w, y: this.state.h-20}}
                                fillLinearGradientColorStops= {[0, 'rgb(138, 178, 28)', 1, 'rgb(0, 86, 36)']}
                            />

                            <RegularPolygon
                                x={this.state.w-11} y={this.state.h-10} 
                                sides={3}
                                fill='rgb(0, 86, 36)'
                                stroke='rgb(0, 86, 36)'
                                radius={10} 
                                rotationDeg={90}
                            />

                            <Text 
                                text="GOVERNANCE ABILITY" fontSize={18} 
                                fontFamily={['Abhaya Libre', 'serif']} 
                                x={20} y={this.state.h-18}
                                align="center" width={this.state.w} height={15}
                                fill='white'
                                fontStyle={'bold'}

                            />

                            <Text 
                                text="RESOURCE DEPENDENCE"
                                fontFamily={['Abhaya Libre', 'serif']} 
                                fontSize={18} 
                                fontStyle={'bold'}
                                x={2} y={this.state.h-50}
                                align="left"
                                width={this.state.h} 
                                height={15}
                                rotation={270}
                                fill='white'
                            />

                            <Text 
                                text="LOW"
                                fontFamily={['Abhaya Libre', 'serif']} 
                                fontSize={15} 
                                fontStyle={'bold'}
                                x={2} y={this.state.h-18}
                                width={this.state.w} height={15}
                                align="left"
                                fill='rgb(251,188,7)'
                            />

                            <Text 
                                text="HIGH"
                                fontFamily={['Abhaya Libre', 'serif']} 
                                fontSize={15} 
                                fontStyle={'bold'}
                                x={2} y={60}
                                width={this.state.w} height={15}
                                align="left"
                                fill='rgb(251,188,7)'
                                rotation={270}
                            />

                            <Text 
                                text="HIGH"
                                fontFamily={['Abhaya Libre', 'serif']} 
                                fontSize={15} 
                                fontStyle={'bold'}
                                x={this.state.w-60} y={this.state.h-18}
                                width={this.state.w} height={15}
                                align="left"
                                fill='rgb(251,188,7)'
                            />

                            <Rect
                                x={20} y={(this.state.h-20)/2} 
                                width={(this.state.w)/2} 
                                height={(this.state.h-20)/2}
                                fill='rgb(163, 197, 223)'
                            />  

                            <Rect
                                x={20} y={0} 
                                width={(this.state.w)/2} 
                                height={(this.state.h-20)/2}
                                fill='rgb(253, 235, 233)'
                            />  

                            <Rect
                                x={(this.state.w)/2} y={0} 
                                width={(this.state.w)/2} 
                                height={(this.state.h-20)/2}
                                fill='rgb(219, 226, 131)'
                            /> 

                            <Rect
                                x={(this.state.w)/2} y={(this.state.h-20)/2} 
                                width={(this.state.w)/2} 
                                height={(this.state.h-20)/2}
                                fill='rgb(255, 251, 219)'
                            />                           
                            <Text 
                                key="ldlg"
                                text="Low Dependence; Low Governance Ability (LDLG) "
                                fontFamily={['Abhaya Libre', 'serif']} 
                                fontSize={18} 
                                fontStyle={'bold'}
                                fill="rgb(0, 138, 210)"
                                x={15} y={this.state.h-60}
                                align="center"
                                width={(this.state.w-15)/2} 
                                height={15}
                            />
                            <Text 
                                key="states"
                                text="States to conserve as per CRZ 2011"
                                fontFamily={['Abhaya Libre', 'serif']} 
                                fontSize={16} 
                                fill="rgb(90, 89, 88)"
                                x={15} y={this.state.h-40}
                                align="center"
                                width={(this.state.w-15)/2} 
                                height={15}
                            />

                            <Text 
                                key="ldhg"
                                text="Low Dependence; High Governance Ability (LDHG) "
                                fontFamily={['Abhaya Libre', 'serif']} 
                                fontSize={18} 
                                fontStyle={'bold'}
                                fill="rgb(168, 123, 31)"
                                x={(this.state.w+15)/2} y={this.state.h-60}
                                align="center"
                                width={(this.state.w-15)/2} 
                                height={15}
                            />
                            <Text 
                                key="expand"
                                text="Expand resource-based livelihood options"
                                fontFamily={['Abhaya Libre', 'serif']} 
                                fontSize={16} 
                                fill="rgb(90, 89, 88)"
                                x={(this.state.w+15)/2} y={this.state.h-40}
                                align="center"
                                width={(this.state.w-15)/2} 
                                height={15}
                            />

                            <Text 
                                key="hdlg"
                                text="High Dependence; Low Governance Ability (HDLG) "
                                fontFamily={['Abhaya Libre', 'serif']} 
                                fontSize={18} 
                                fill="rgb(118, 84, 125)"
                                x={15} y={10}
                                fontStyle={'bold'}
                                align="center"
                                width={(this.state.w-15)/2} 
                                height={15}
                            />
                            <Text 
                                key="strengthen"
                                text="Strengthen capacity to govern"
                                fontFamily={['Abhaya Libre', 'serif']} 
                                fontSize={16} 
                                fill="rgb(90, 89, 88)"
                                x={15} y={30}
                                align="center"
                                width={(this.state.w-15)/2} 
                                height={15}
                            />

                            <Text 
                                key="hdhg"
                                text="High Dependence; High Governance Ability (HDHG)"
                                fontFamily={['Abhaya Libre', 'serif']} 
                                fontSize={18}
                                fontStyle={'bold'} 
                                fill="rgb(70, 172, 79)"
                                x={(this.state.w+15)/2} y={10}
                                align="center"
                                width={(this.state.w-15)/2} 
                                height={15}
                            />

                            <Text 
                                key="implement"
                                text="Implement community-based resource management"
                                fontFamily={['Abhaya Libre', 'serif']} 
                                fontSize={16} 
                                fill="rgb(90, 89, 88)"
                                x={(this.state.w+15)/2} y={30}
                                align="center"
                                width={(this.state.w-15)/2} 
                                height={15}
                            />  
                      
                            {circles}

                        </Layer>
                    </Stage>
                </SimplePanel>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
            getRA: (params) => dispatch(getRA(params)),
        }
  }    
  
export default connect(null, mapDispatchToProps)(CVCAGovernance);