import React from 'react';
import './App.css';
import Map from './Components/Map'
import DetailPane from './Components/DetailPane'
import Login from './Components/Login'
import { connect } from 'react-redux';
import mangroves from './Mangroves.svg'
import corals from './Corals.svg'
import seagrass from './Seagrass.svg'
import saltmarsh from './Saltmarsh.svg'
import Thresholds from './Components/Thresholds'
import Governance from './Components/Governance'
import CVCAGovernance from './Components/CVCAGovernance'
import SimpleBackdrop from './Components/SimpleBackdrop'
import { signIn } from './store/Actions/SignInAction'
import { getCVCA } from './store/Actions/GetCVCAAction';
import {isEmpty} from './Components/Utils';
import LinearProgress from '@material-ui/core/LinearProgress';
import Fade from '@material-ui/core/Fade';
import ESA from '@material-ui/icons/EcoRounded'
import CVCA from '@material-ui/icons/FlareRounded'
import NDA from '@material-ui/icons/ListAltRounded'
//import ICZM from '@material-ui/icons/WavesRounded'
import logo from './esad.svg'
//import EsriMap from './Components/EsriMap';


const ecosystemTypes = [
  { id: "2019-man-IN", name: "Mangroves", color : "green" , icon: mangroves },
  { id: "2019-cor-IN", name: "Corals", color : "coral" , icon: corals },
  { id: "2019-sea-IN", name: "Seagrass", color : "#8db600" , icon: seagrass },
  { id: "2019-sal-IN", name: "Saltmarshes", color : "goldenrod" , icon: saltmarsh }
];

const tabs = [
  { id: "esa", name: "ESA", icon: <ESA className="menuicon" style={{fontSize: '50px'}} />, longname:"Ecologically Sensitive Areas"},
  { id: "cvca", name: "CVCA", icon: <CVCA className="menuicon" style={{fontSize: '50px'}} />, longname:"Critically Vulnerable Coastal Areas" },
/*   { id: "iczm", name: "ICZM", icon: <ICZM className="menuicon" style={{fontSize: '50px'}} />, longname:"Integrated Coastal Zone Management" },
 */   { id: "nda", name: "Planning Data", icon: <NDA className="menuicon" style={{fontSize: '50px'}} />, longname:"Planning Data" } 
];

function App(props) {
  const [login, setLogin] = React.useState(false);
  const [loading, setLoading]  = React.useState(false);
  const [backdrop, setBackdrop] = React.useState(true);
  const [fullScreen, setFullScreen] = React.useState('none');
  const [captions, setCaptions] = React.useState(true);
  const [labels, setLabels] = React.useState(true);
  const [hszRange, setHSZRange] = React.useState([0, 1]);
  const [selectedTab, setSelectedTab] = React.useState(null);
  const [selectedRA, setSelectedRA] = React.useState(null);
  const [mapStyle, setMapStyle] = React.useState({name: 'Light', style: "mapbox://styles/mapbox/light-v10"});
  const [selectedNDA, setSelectedNDA] = React.useState(null);

  let viewport = { width: '100%', height: '100%', latitude: 23.259933, longitude: 77.412613, zoom: 4 };
  let level = 0;
  let fillcolor = 'white';
  let ecosystem = null;
  let ecosystemname = '';

  if(selectedTab === null){
    //do nothing
  }
  else if(selectedTab.id === 'esa'){
    if(isEmpty(props.ecoProfile)){
      level = 0;
    }
    else{
      level = props.ecoProfile.level;
      ecosystem = ecosystemTypes.find((ecosystemType) => {
        if(props.ecoProfile.id.startsWith(ecosystemType.id)){
          return ecosystemType;
        }else{
          return null;
        }
      });

      fillcolor = (ecosystem != null) ? ecosystem.color : 'white';
      ecosystemname = (ecosystem != null) ? ecosystem.name : '';
      viewport = { ...viewport, 
                longitude : props.ecoProfile.viewport.longitude, 
                latitude : props.ecoProfile.viewport.latitude, 
                zoom : props.ecoProfile.viewport.zoom };  
    }
  }
  else if(selectedTab.id === 'cvca'){
    const selectedCVCA = props.cvcas !== undefined && props.cvcas !== null && props.cvca !== undefined && props.cvca !== null ? 
    props.cvcas.find(cvca => cvca.id === props.cvca.id) : null;

    const sRA = selectedRA !== undefined && selectedRA !== null && 
                        props.cvca.resourceareas !== undefined && props.cvca.resourceareas !== null ?
                        props.cvca.resourceareas.find(resourcearea => resourcearea.id === selectedRA.id) :
                        null; 
    viewport = selectedCVCA !== undefined && selectedCVCA !== null ? 
                    (selectedRA !== undefined && selectedRA !== null ) ?
                    {...viewport, latitude: sRA.viewport.latitude, longitude: sRA.viewport.longitude, zoom: sRA.viewport.zoom} :
                    {...viewport, latitude: selectedCVCA.viewport.latitude, longitude: selectedCVCA.viewport.longitude, zoom: selectedCVCA.viewport.zoom} :
                    {...viewport, latitude: 17.38714, longitude: 78.491684, zoom: 4.8};
  }
  


  const {signIn} = props;
  React.useEffect(() => {
    signIn();

    if(level >= 1){
      setLoading(false);
    }

  }, [signIn, level, props.ecoProfile.id])
  
  function showLogin(){
    setLogin(true);
  }
  function hideLogin(){
    setLogin(false);
  }
  function showFullScreen(widgetid){
    setFullScreen(widgetid);
  }

  function applySettings(captions, labels, hszRange){
    setLabels(labels);
    setCaptions(captions);
    setHSZRange(hszRange);
  }

  function selectTab(tab){
    if(typeof props.user !== 'undefined' && props.user != null && !isEmpty(props.user)) 
    {
        setFullScreen('none');
        setSelectedTab(tab);
        
        if(tab.id === 'cvca'){
          setLoading(true);
          props.getCVCA();
        }
    }
    else{
        showLogin();
    }
  }

  function selectRA(ra){
    //console.log(ra)
    setSelectedRA(ra);
  }

  function selectNDA(nda){
    setSelectedNDA(nda);
  }

  const mappane = <Map 
                    key={selectedTab === null ? "blankmap" : selectedTab !== null && selectedTab.id === 'esa' ? props.ecoProfile.id : 
                        props.cvca === undefined || props.cvca === null || isEmpty(props.cvca) ? 
                        "cvca" : selectedRA !== null ? selectedRA.id : props.cvca.id
                      }
                    viewport={viewport} 
                    fillcolor={fillcolor} 
                    level={level} 
                    ecoProfile={props.ecoProfile} 
                    loading={setLoading}
                    backdrop={setBackdrop}
                    showFullScreen={showFullScreen}
                    fullScreen={fullScreen}
                    selectedTab={selectedTab}
                    selectRA={selectRA}
                    selectedRA={selectedRA}
                    mapStyle={mapStyle}
                    setMapStyle={setMapStyle}
                    selectedNDA={selectedNDA}
                    selectNDA={selectNDA}
                  /> 

  const selectedPatch = props.ecoProfile !== null && typeof props.ecoProfile.patches !== 'undefined' ? 
                      props.ecoProfile.patches.find(patch => patch.id === props.ecoProfile.id)
                      : null;

  let rightPane = null;

  if(selectedTab !== null && selectedTab.id === 'esa'){
    if(level < 2 || fullScreen === "Map"){
      rightPane = (<div className="mappane">{mappane}</div>);
    }
    if(level >= 2 && fullScreen === "none"){
      const cname = selectedPatch !== undefined && selectedPatch !== null ? "mapcontainer" : "mapcontainer_stretch";
      rightPane = [ <div className={cname} key="container">
      <div className="framework" key="framework" style={{display:'flex', flexDirection:'column'}}>
      <Governance 
          key={props.ecoProfile.id} //'key' value important for refreshing the matrix
          fillcolor={fillcolor} 
          ecoProfile={props.ecoProfile}
          regions={props.ecoProfile.patches} 
          showCaptions={captions}
          showLabels={labels}
          hszRange={hszRange}
          level={level}
          loading={setLoading}
          fullscreen={false}
          stretch={selectedPatch === undefined || selectedPatch === null}
          showFullScreen={showFullScreen}
          applySettings={applySettings}
          selected={props.ecoProfile.id}
        />
      </div>
      <div className="mappanenarrow" key="map">
        {/* <EsriMap/> */}
        {mappane}
      </div>
      </div>];
  
      if(typeof selectedPatch !== 'undefined' && selectedPatch !== null ){
        rightPane.push(<div className="thresholds" key="thresholds">
        <Thresholds 
          key={props.ecoProfile.id} 
          fillcolor={fillcolor} 
          name={selectedPatch.name}
          categoryvalues={selectedPatch.categoryvalues}
          criteriavalues={props.ecoProfile.framework[0].criteriavalues}
          fullscreen={false}
          showFullScreen={showFullScreen}
      />
      </div>);
      }
    }
    else if(fullScreen === "Governance"){
      rightPane = (<div className="framework_fs" key="framework" style={{display:'flex', flexDirection:'column'}}>
      <Governance 
          key={props.ecoProfile.id} //'key' value important for refreshing the matrix
          fillcolor={fillcolor} 
          ecoProfile={props.ecoProfile}
          regions={props.ecoProfile.patches} 
          showCaptions={captions}
          showLabels={labels}
          hszRange={hszRange}
          level={level}
          loading={setLoading}
          fullScreen={true}
          stretch={false}
          showFullScreen={showFullScreen}
          applySettings={applySettings}
          selected={props.ecoProfile.id}
        />
      </div>)
    }
    else if(level >= 4 && fullScreen === "Thresholds"){
      rightPane = (<div className="thresholds_fs" key="thresholds">
          {typeof selectedPatch !== 'undefined' && selectedPatch !== null ?
            <Thresholds 
              fillcolor={fillcolor} 
              name={selectedPatch.name}
              categoryvalues={selectedPatch.categoryvalues}
              criteriavalues={props.ecoProfile.framework[0].criteriavalues}
              fullScreen={true}
              showFullScreen={showFullScreen}         
            /> 
           : <Thresholds empty={true} />
          }
      </div>)
    }
  }
  else if(selectedTab !== null && selectedTab.id === 'cvca'){
    if(props.cvca === undefined || props.cvca === null || isEmpty(props.cvca) ){
      rightPane = (<div className="mappane">{mappane}</div>);
    }
    else{
      //console.log("Loading "+loading);

      rightPane = [  
      <div className="mapcontainer_stretch" key="container">
        <div className="cvca_framework" key="framework" style={{display:'flex', flexDirection:'column'}}>
        <CVCAGovernance 
          key={props.cvca.id} //'key' value important for refreshing the matrix
          ecoProfile={props.cvca.id}
          regions={props.cvca.framework} 
          loading={setLoading}
          selectRA={selectRA}
          selectedRA={selectedRA}
          name={props.cvca.name}
        />
        </div>
        <div className="mappanenarrow" key="map">
          {mappane}
        </div>
      </div>];
    }
  }
  else{
    rightPane = (<div className="mappane">{mappane}</div>);
  }


  const tabMenu = tabs.map((tab, i) => {
    return (
          <button id={i} key={i}
                className={selectedTab !== null && tab.id === selectedTab.id ? 'tabitemselected' : 'tabitem'} 
                onClick={() => selectTab(tab)}>
              <div className="tabItemImg" id={'icon'+i}>
                {tab.icon}
              </div>
              <div className="tabItemText" id={'text'+i}>
                {tab.name}
            </div>
          </button>
    )
  });

    return (
      <div>
        <div className="loader" style={{height:'3px', width:'100%'}}>
          <Fade in={loading} unmountOnExit style={{transitionDelay: loading ? '800ms' : '0ms'}}>
            <LinearProgress />
          </Fade>
        </div>
       <div className="App">
         <div className="leftpane">
            <div className="centralpane">
             <div className="tabbar">
              <div className="titleimage" >
                <img src={logo} height="75px" alt="ESA Dashboard" />
              </div>
                {tabMenu}
              </div>
              <div className="detailspane" >
                <DetailPane 
                  key={selectedTab !== null && selectedTab === "esa" ? props.ecoProfile.id : props.cvca}
                  ecoProfile={props.ecoProfile}
                  fillcolor={fillcolor}   
                  ecosystemname={ecosystemname} 
                  level={level}   
                  loggedIn={props.user}
                  showLogin={showLogin}
                  loading={setLoading}
                  showFullScreen={showFullScreen}
                  fullScreen={fullScreen}
                  selectedTab={selectedTab}
                  ecosystemTypes={ecosystemTypes} 
                  selectRA={selectRA}
                  selectedRA={selectedRA}
                  selectedNDA={selectedNDA}
                  selectNDA={selectNDA}
                /> 
              </div>
            </div>
         </div>
         {rightPane}
       <Login open={login} close={hideLogin} />
       <SimpleBackdrop backdrop={backdrop} />
       </div>
       </div>
      );
 
}

const mapDispatchToProps = (dispatch) => {
  return {
          signIn: (params) => dispatch(signIn()),
          getCVCA: (params) => dispatch(getCVCA(params)),
      }
}    

const mapStateToProps = state => {
  return {
    ecoProfile: state.getEco.EcoProfile,
    cvcas: state.getCVCA.CVCAList,
    cvca: state.getRA.CVCA,
    user: state.signIn.user,
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(App);