import React from 'react';
import CategorySlider from './CategorySlider'
import SimplePanel from './SimplePanel'
import ReactEchartsCore from 'echarts-for-react/lib/core';
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/sunburst'
import {getSunburstData, getCategoryNames} from './Utils';
import Max from '@material-ui/icons/FullscreenOutlined';
import Min from '@material-ui/icons/FullscreenExitOutlined';

class Thresholds extends React.Component {
    constructor(props){
      super(props)
      this.state = {innerWidth: window.innerWidth};
      this.updateDimensions = this.updateDimensions.bind(this);
    }

    updateDimensions() {
        this.setState({innerWidth: window.innerWidth});
    };

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render(){
        if(this.props.empty === true){
            return (
                <SimplePanel title="HSZ Threshold summary" addStyle={{height:'100%',borderRadius:'0px'}} >
                    Select a patch
                </SimplePanel>
            );
        }

        else{
            const options = this.props.fullScreen ? 
            {
                series: {
                    type: 'sunburst', radius:['1%','100%'], sort: null, startAngle:"120",
                    data: getSunburstData(this.props.categoryvalues,  this.props.criteriavalues),
                    levels: [{}, {label:{ padding: 5, rotate: 0, fontSize: 16 }}, {label:{ fontSize: 16} }]
                }
            } :
            {
                series: {
                    type: 'sunburst', radius:['1%','100%'], sort: null, startAngle:"120",
                    data: getSunburstData(this.props.categoryvalues,  this.props.criteriavalues),
                    levels: [{}, {label:{ padding: 5, rotate: 0, fontSize: 12 }}, {label:{ fontSize: 11} }]
                }
            } ;

            const categories = getCategoryNames().map((category, i) => {
                return (
                    <CategorySlider key={i} value={Math.floor(this.props.categoryvalues[i])} name={category} />
                )
            });

            const title = "HSZ Threshold summary - "+this.props.name;
            const buttons = this.props.fullScreen ? 
                <div className="listpanelbuttonbar" style={{display: 'flex',flexDirection: 'row',justifyContent: 'space-between'}}>
                <Min style={{
                        color: this.props.fillcolor,
                        cursor: "pointer"
                    }} 
                onClick={() => this.props.showFullScreen('none')} /></div> :
                <div className="listpanelbuttonbar" style={{display: 'flex',flexDirection: 'row',justifyContent: 'space-between'}}>
                <Max style={{
                    color: this.props.fillcolor,
                    cursor: "pointer"
                }} 
                onClick={() => this.props.showFullScreen('Thresholds')} />
                </div>


            let thresholds = null;
            if( !this.props.fullScreen){
                thresholds = (
                        [<SimplePanel key="wagonwheel" title={title} addStyle={{height:'100%',borderRadius:'0px', flex: 1}} buttons={buttons}>
                        <div> 
                            <ReactEchartsCore 
                                echarts={echarts} 
                                style={{height: ((0.3*this.state.innerWidth) - 40)}} 
                                option={options} 
                                plugins={[{
                                    beforeInit: function (chart) {
                                      chart.data.labels.forEach(function (e, i, a) {
                                            if (/\n/.test(e)) {
                                            a[i] = e.split(/\n/)
                                            }
                                        })
                                    }
                                }]}
                            />
                        </div> 
                        </SimplePanel>,
                        <div className="simplepanel" style={{borderRadius:'0px', flex:2, justifyContent: 'center'}} key="categories" >
                        <div style={{padding: '30px'}}>
                            {categories}
                        </div></div>]
                )
            }
            else{
                thresholds = (
                    <SimplePanel title={title} addStyle={{height:'100%',borderRadius:'0px'}} buttons={buttons}>
                         <div>
                            <ReactEchartsCore echarts={echarts} style={{height: ((0.48*this.state.innerWidth) - 40)}} option={options} />
                        </div>
                    </SimplePanel>
                )
            }

            return (
                <div style={{display: 'flex',flexDirection: 'column',justifyContent: 'space-around', height:'100%'}}>
                    {thresholds}
                </div>
            );
        }
    }
}

export default Thresholds;