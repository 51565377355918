import {combineReducers} from 'redux'
import GetEcosystemProfileReducer from './GetEcosystemProfileReducer'
import SignInReducer from './SignInReducer'
import SignOutReducer from './SignOutReducer'
import GetEcoReducer from './GetEcoReducer'
import GetPatchReducer from './GetPatchReducer'
import GetCVCAReducer from './GetCVCAReducer'
import GetRAReducer from './GetRAReducer'


const AppReducer = combineReducers({
    getEcoProfile: GetEcosystemProfileReducer,
    signIn: SignInReducer,
    signOut: SignOutReducer,
    getEco: GetEcoReducer,
    getPatch: GetPatchReducer,
    getCVCA: GetCVCAReducer,
    getRA: GetRAReducer
})

const RootReducer = (state, action) => {
    if (action.type === 'LOGOUT_SUCCESS') {
        state = undefined
      }
    return AppReducer(state, action)
  }

export default RootReducer