import React from 'react';
import SimplePanel from './SimplePanel'
import {Layer, Rect, Stage, Text, Arrow, Line, Circle} from 'react-konva';
import {getColor} from './Utils';
import SettingsIcon from '@material-ui/icons/TuneOutlined';
import Max from '@material-ui/icons/FullscreenOutlined';
import Min from '@material-ui/icons/FullscreenExitOutlined';
import { connect } from 'react-redux';
import { getPatch } from '../store/Actions/GetPatchAction'
import Settings from './Settings';

class Governance extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            w: this.props.fullScreen || this.props.stretch ?  window.innerWidth*0.6-20 : window.innerWidth*0.3-20, 
            h: this.props.fullScreen ?  window.innerHeight-100 : window.innerHeight*0.5-100,  
            cursor : 'default',
            showSettings: false,
        };
        this.updateDimensions = this.updateDimensions.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.onMouseOver = this.onMouseOver.bind(this);
        this.onMouseOut = this.onMouseOut.bind(this);
        this.toggleCaptions = this.toggleCaptions.bind(this);
        this.toggleSettings = this.toggleSettings.bind(this);
        this.applySettings = this.applySettings.bind(this);

        this.circleRefArray = [];
        this.textRefArray = [];
    }

    
    toggleSettings(){
        this.setState({...this.state, showSettings: !this.state.showSettings});
    }

    applySettings(caption, label, hszvalue){
        this.props.applySettings(caption, label, hszvalue);
        this.updateDimensions();
        this.toggleSettings();

    }
  
    updateDimensions() {
        this.setState({...this.state, 
            w: this.props.fullScreen || this.props.stretch ?  window.innerWidth*0.6-20 : window.innerWidth*0.3-20, 
            h: this.props.fullScreen ?  window.innerHeight-100 : window.innerHeight*0.5-100, 
            cursor : 'default'
        });

        const regions = this.props.regions.filter((region) => region.value >= this.props.hszRange[0]*100 && region.value <= this.props.hszRange[1]*100);

        this.circleRefArray = new Array(regions.length);
        this.textRefArray = new Array(regions.length);
        for(let i=0; i<regions.length; i++){
            this.circleRefArray[i] = React.createRef();
            this.textRefArray[i] = React.createRef();
        }
    };
 
    toggleCaptions(){
        this.setState({...this.state, captions: !this.state.captions});
    }
 
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.updateDimensions();        
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    onMouseOver(e){
        if(e.target.attrs.id !== undefined){
            const cursor = typeof e.target.attrs.id != 'undefined' ? 'pointer' : 'default';
            e.target.stroke("yellow");
            e.target.strokeWidth(5);

            this.setState({...this.state, cursor:cursor});
        }
    }

    onMouseOut(e){
        if(e.target.attrs.id !== undefined && this.props.selected !== null && this.props.selected !== e.target.attrs.id){
            e.target.stroke("none");
            e.target.strokeWidth(0);
            this.setState({...this.state, cursor:'default'});
        }
    }
    handleClick(e){
        if(typeof e.target.attrs.id !== 'undefined'){
            this.props.loading(true);
            this.props.showFullScreen("none");
            this.props.getPatch({id : e.target.attrs.id});
        }
    }
    
    render(){
        const regions = this.props.regions.filter((region) => region.value >= this.props.hszRange[0]*100 && region.value <= this.props.hszRange[1]*100);
        const circles = typeof regions !== 'undefined' ?  
        regions.map((patch, i) => {
             return (
                [<Circle 
                    key={patch.id} id={patch.id}
                    x={patch.development === 0 ? 15 : patch.development === 100 ? this.state.w - 15 : (patch.development * this.state.w / 100)} 
                    y={patch.conservation === 0 ? this.state.h-15 : patch.conservation === 100 ? 15 : ((100 - patch.conservation) * this.state.h / 100)}
                    radius={15} fill={getColor(patch.value)}
                    stroke={this.props.selected !== null && this.props.selected === patch.id ? 'yellow' : 'none'}
                    strokeWidth={this.props.selected !== null && this.props.selected === patch.id ? 5 : 0}
                    ref={ref => (this.circleRefArray[i] = ref)}
                />,
                 <Text 
                    key={patch.id+'_title'} 
                    text={this.props.showLabels ? patch.name.substring(patch.name.lastIndexOf('-')+1) : ""} fontSize={14} 
                    x={patch.development === 0 ? -45 : patch.development === 100 ? this.state.w -75 : (patch.development * this.state.w / 100)-60}
                    y={patch.conservation === 0 ? this.state.h-21 : patch.conservation === 100 ? 9 : ((100 - patch.conservation) * this.state.h / 100)-6}
                    fontFamily={['Abhaya Libre', 'serif']} 
                    align="center" width={120} height={5}
                    fill={'white'}
                    ref={ref => (this.textRefArray[i] = ref)}
                />]                
            )
        }) : null;

        const max_min = this.props.fullScreen ?
                        <Min style={{
                                color: this.props.fillcolor,
                                cursor: "pointer",
                                paddingLeft: '10px',
                            }} 
                        onClick={() => this.props.showFullScreen('none')} /> :
                        <Max style={{
                            color: this.props.fillcolor,
                            cursor: "pointer",
                            paddingLeft: '10px',
                        }} 
                        onClick={() => this.props.showFullScreen('Governance')} />

        const buttons = <div className="listpanelbuttonbar" style={{display: 'flex',flexDirection: 'row',justifyContent: 'space-between'}}>
                           <SettingsIcon style={{
                                color: this.props.fillcolor,
                                cursor: "pointer",
                                paddingLeft: '10px',
                            }} 
                            onClick={this.toggleSettings} /> 
                            {max_min}
                        </div>

        return (
                <SimplePanel 
                    title="Decision Matrix" 
                    addStyle={{
                        borderRadius:'0px',
                        }}
                    buttons={buttons}
                >
                    <Stage 
                        className="matrix" 
                        width={this.state.w} 
                        height={this.state.h+15}
                        onClick={this.handleClick}
                        onMouseOver={this.onMouseOver}
                        onMouseOut={this.onMouseOut}
                        style={{cursor:this.state.cursor}}
                    >
                    
                        <Layer>
                            <Line 
                                points={[10,this.state.h-6,10, this.props.fullScreen ? (this.state.h*2+6)/3 : (this.state.h*4+6)/5]}
                                fill= 'red' stroke='red' strokeWidth={2} 
                            />
                            <Arrow 
                                points={[10, this.props.fullScreen ? this.state.h/3-6 : this.state.h/5-6,10,5]}
                                fill= 'rgb(118, 194, 0)' stroke='rgb(118, 194, 0)' strokeWidth={2} 
                            />
                            <Line 
                                points={[(this.state.w/3)+5,this.state.h-6,10,this.state.h-6]}
                                fill= 'rgb(118, 194, 0)' stroke='rgb(118, 194, 0)' strokeWidth={2} 
                            />
                            <Arrow 
                                points={[2*this.state.w/3, this.state.h-6, this.state.w-8, this.state.h-6]}
                                fill= 'red' stroke='red' strokeWidth={2} 
                            />  

                            <Text 
                                text="Development Index *" fontSize={this.props.fullScreen ? 18 : 14} 
                                fontFamily={['Abhaya Libre', 'serif']} 
                                x={0} y={this.state.h-13}
                                align="center" width={this.state.w} height={15}
                                //fill='rgb(251,188,7)'
                            />

                            <Text 
                                text="Calculated as One minus Development Index (1-DI)" fontSize={this.props.fullScreen ? 13 : 12} 
                                fontFamily={['Abhaya Libre', 'serif']} 
                                x={0} y={this.state.h}
                                align="center" width={this.state.w} height={15}
                                //fill='rgb(251,188,7)'
                            />

                            <Text 
                                text="Low" fontSize={this.props.fullScreen ? 13 : 12} 
                                fontFamily={['Abhaya Libre', 'serif']} 
                                x={8} y={this.state.h-4}
                                align="left" width={this.state.w} height={15}
                                //fill='rgb(251,188,7)'
                            />

                            <Text 
                                text="High" fontSize={this.props.fullScreen ? 13 : 12} 
                                fontFamily={['Abhaya Libre', 'serif']} 
                                x={0} y={this.state.h-4}
                                align="right" width={this.state.w-20} height={15}
                                //fill='rgb(251,188,7)'
                            />
                            
                            <Text 
                                text="Conservation Value Index"
                                fontFamily={['Abhaya Libre', 'serif']} 
                                fontSize={this.props.fullScreen ? 18 : 15} 
                                x={this.props.fullScreen ? 0 : 2} y={this.state.h}
                                align="center"
                                width={this.state.h} 
                                height={15}
                                rotation={270}
                                //fill='rgb(251,188,7)'
                            /> 

                            <Text 
                                text="Low"
                                fontFamily={['Abhaya Libre', 'serif']} 
                                fontSize={this.props.fullScreen ? 13 : 12} 
                                x={-2} y={this.state.h-5}
                                align="left"
                                width={this.state.h} 
                                height={15}
                                rotation={270}
                                //fill='rgb(251,188,7)'
                            /> 

                            <Text 
                                text="High"
                                fontFamily={['Abhaya Libre', 'serif']} 
                                fontSize={this.props.fullScreen ? 13 : 12} 
                                x={-2} y={this.state.h+17}
                                align="right"
                                width={this.state.h} 
                                height={15}
                                rotation={270}
                                //fill='rgb(251,188,7)'
                            /> 
                            
                            <Rect
                                x={15} y={(this.state.h-15)/2} 
                                width={(this.state.w-20)/2} 
                                height={(this.state.h-15)/2}
                                fill='rgb(0, 175, 80)'
                            />  

                            <Rect
                                x={15} y={0} 
                                width={(this.state.w-20)/2} 
                                height={(this.state.h-15)/2}
                                fill='rgb(218, 192, 155)'
                            />  

                            <Rect
                                x={(this.state.w+15)/2 - 2} y={0} 
                                width={(this.state.w-20)/2} 
                                height={(this.state.h-15)/2}
                                fill='red'
                            /> 

                            <Rect
                                x={(this.state.w+15)/2-2} y={(this.state.h-15)/2} 
                                width={(this.state.w-20)/2} 
                                height={(this.state.h-15)/2}
                                fill='rgb(255,153, 52)'
                            />

                            { this.props.showCaptions ?  
                            [<Text 
                                key="LCLD"
                                text="LCLD"
                                fontFamily={['Abhaya Libre', 'serif']} 
                                fontSize={18} 
                                fill="white"
                                x={15} y={this.state.h/2+10}
                                align="center"
                                width={(this.state.w-15)/2} 
                                height={15}
                            />,
                            <Text 
                                key="lowconservation1"
                                text="Low Conservation"
                                fontFamily={['Abhaya Libre', 'serif']} 
                                fontSize={18} 
                                fill="white"
                                x={15} y={this.state.h/2+30}
                                align="center"
                                width={(this.state.w-15)/2} 
                                height={15}
                            />,
                            <Text 
                            key="lowdevelopment2"
                            text="Low Development"
                            fontFamily={['Abhaya Libre', 'serif']} 
                            fontSize={18} 
                            fill="white"
                            x={15} y={this.state.h/2+50}
                            align="center"
                            width={(this.state.w-15)/2} 
                            height={15}
                        />,
                            <Text 
                            key="pristineconserve"
                            text="PRISTINE - CONSERVE"
                            fontFamily={['Abhaya Libre', 'serif']} 
                            fontSize={18} 
                            fill="white"
                            x={15} y={this.state.h/2+80}
                            align="center"
                            width={(this.state.w-15)/2} 
                            height={15}
                        />,


                            <Text 
                                key="LCHD"
                                text="LCHD"
                                fontFamily={['Abhaya Libre', 'serif']} 
                                fontSize={18} 
                                fill="white"
                                x={(this.state.w+15)/2} y={this.state.h/2+10}
                                align="center"
                                width={(this.state.w-15)/2} 
                                height={15}
                            />,
                            <Text 
                                key="lowconservation2"
                                text="Low Conservation"
                                fontFamily={['Abhaya Libre', 'serif']} 
                                fontSize={18} 
                                fill="white"
                                x={(this.state.w+15)/2} y={this.state.h/2+30}
                                align="center"
                                width={(this.state.w-15)/2} 
                                height={15}
                            />,
                            <Text 
                            key="highdevelopment2"
                            text="High Development"
                            fontFamily={['Abhaya Libre', 'serif']} 
                            fontSize={18} 
                            fill="white"
                            x={(this.state.w+15)/2} y={this.state.h/2+50}
                            align="center"
                            width={(this.state.w-15)/2} 
                            height={15}
                        />,
                            <Text 
                            key="stressedregulate"
                            text="STRESSED - REGULATE"
                            fontFamily={['Abhaya Libre', 'serif']} 
                            fontSize={18} 
                            fill="white"
                            x={(this.state.w+15)/2} y={this.state.h/2+80}
                            align="center"
                            width={(this.state.w-15)/2} 
                            height={15}
                        />,

                            <Text 
                                key="HCLD"
                                text="HCLD"
                                fontFamily={['Abhaya Libre', 'serif']} 
                                fontSize={18} 
                                fill="black"
                                x={15} y={10}
                                align="center"
                                width={(this.state.w-15)/2} 
                                height={15}
                            />,
                            <Text 
                                key="highconservation1"
                                text="High Conservation"
                                fontFamily={['Abhaya Libre', 'serif']} 
                                fontSize={18} 
                                fill="black"
                                x={15} y={30}
                                align="center"
                                width={(this.state.w-15)/2} 
                                height={15}
                            />,
                            <Text 
                                key="lowdevelopment1"
                                text="Low Development"
                                fontFamily={['Abhaya Libre', 'serif']} 
                                fontSize={18} 
                                fill="black"
                                x={15} y={50}
                                align="center"
                                width={(this.state.w-15)/2} 
                                height={15}
                            />,
                            <Text 
                                key="vulnerablemanage"
                                text="VULNERABLE - MANAGE"
                                fontFamily={['Abhaya Libre', 'serif']} 
                                fontSize={18} 
                                fill="black"
                                x={15} y={80}
                                align="center"
                                width={(this.state.w-15)/2} 
                                height={15}
                            />,

                            <Text 
                            key="HCHD"
                            text="HCHD"
                            fontFamily={['Abhaya Libre', 'serif']} 
                            fontSize={18} 
                            fill="white"
                            x={(this.state.w+15)/2} y={10}
                            align="center"
                            width={(this.state.w-15)/2} 
                            height={15}
                        />,

                            <Text 
                                key="highconservation2"
                                text="High Conservation"
                                fontFamily={['Abhaya Libre', 'serif']} 
                                fontSize={18} 
                                fill="white"
                                x={(this.state.w+15)/2} y={30}
                                align="center"
                                width={(this.state.w-15)/2} 
                                height={15}
                            />,

                            <Text 
                            key="highdevelopment1"
                            text="High Development"
                            fontFamily={['Abhaya Libre', 'serif']} 
                            fontSize={18} 
                            fill="white"
                            x={(this.state.w+15)/2} y={50}
                            align="center"
                            width={(this.state.w-15)/2} 
                            height={15}
                        />,

                        <Text 
                        key="highlystressed"
                        text="HIGHLY STRESSED - "
                        fontFamily={['Abhaya Libre', 'serif']} 
                        fontSize={18} 
                        fill="white"
                        x={(this.state.w+15)/2} y={80}
                        align="center"
                        width={(this.state.w-15)/2} 
                        height={15}
                    />,

                        <Text 
                        key="protect"
                        text="PROTECT"
                        fontFamily={['Abhaya Libre', 'serif']} 
                        fontSize={18} 
                        fill="white"
                        x={(this.state.w+15)/2} y={100}
                        align="center"
                        width={(this.state.w-15)/2} 
                        height={15}
                    />

                   ]

                            : null }
                          
                            {circles}

                        </Layer>
                    </Stage>
                    <Settings 
                        open={this.state.showSettings} 
                        toggleSettings={this.toggleSettings} 
                        applySettings={this.applySettings}
                        captions={this.props.showCaptions}
                        labels={this.props.showLabels}
                        hszRange={this.props.hszRange}
                    />
                </SimplePanel>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
            getPatch: (params) => dispatch(getPatch(params)),
        }
  }    
  
export default connect(null, mapDispatchToProps)(Governance);