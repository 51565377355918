import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux'
import RootReducer from './store/Reducers/RootReducer'
import thunk from 'redux-thunk'

//Amplify code -  start
import Amplify from 'aws-amplify'
import config from './aws-exports'  
Amplify.configure(config)
//Amplify code -  end


const store = createStore(RootReducer, applyMiddleware(thunk));

ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
