import React from 'react';
//import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function ConfirmDialog(props) {
  return (
    <div>
      <Dialog
        open={props.open}
        disableBackdropClick={true}
        onClose={() => props.close()}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move', borderBottom: '1px groove lightgray' }} id="draggable-dialog-title">
          <b>{props.title}</b>
        </DialogTitle>

        <DialogContent>
        <div>
            <div style={{fontSize: 'larger'}}>{props.content}</div>
            <div style={{fontSize: 'large'}}><ul><li>{props.patch}</li></ul></div>
        </div>
        </DialogContent>
        <DialogActions>
            <button className="bbutton" onClick={() => props.close()} > {props.cancel} </button>
                <button 
                    className="bbutton" 
                    onClick={props.handleSubmit} 
                    style={{
                        color:'white', 
                        backgroundColor:'rgb(74, 119, 229)',
                        width:'auto'
                    }}
                > {props.submit} </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}