import React from 'react';
import SimplePanel from './SimplePanel'

export default function BiodiversityPanel(props){
    const [selected, setSelected] = React.useState("");

    const  handleClick = (reff, speciestype, name) => {
        props.getFullList(speciestype, name);
        setSelected(speciestype);
        /* if(typeof reff !== 'undefined'){
            reff.current.scrollIntoView({block: 'start', behavior: 'smooth'});
        } */
    }

    const contents = (!props.skeleton && typeof props.metrics !== 'undefined') ? props.metrics.map((metric, i) => {
        return (
            <div className="biodiversitycontent"  key={metric.id}>
                <div >{metric.desc}</div>
                <div style={{padding: '2px'}}>

                    { typeof props.speciesref !== 'undefined' && metric.value !== 0? 
                        <button 
                            className="bbutton1" 
                            onClick={() => handleClick(props.speciesref, metric.id, metric.desc )} 
                            style={selected === metric.id ? 
                                    {color:'white', backgroundColor:props.fillcolor, border:'1px solid '+props.fillcolor} : 
                                    {color: props.fillcolor, backgroundColor: 'white', border:'1px solid '+props.fillcolor}
                                }
                        >
                            {metric.value}
                        </button>
                    : <button className="bbutton1" style={{color:'white', backgroundColor:'gray', cursor: 'auto'}}>
                            {metric.value}
                        </button>
                    }
                </div>
            </div>
        )
    }) : 'No data';
    return (
        <SimplePanel title="Biodiversity" addStyle={{borderTop: '2px solid '+props.fillcolor}} addContentStyle={{textAlign:'left'}}>
            {contents}
        </SimplePanel>
    )
}
