import React from 'react';
//import Lock from '@material-ui/icons/Https'
//import Close from '@material-ui/icons/DirectionsRun'
import { connect } from 'react-redux'
import { signOut } from '../store/Actions/SignOutAction'
import IconButton from '@material-ui/core/IconButton';
//import preval from 'preval.macro'
import {isEmpty} from './Utils';

function Header(props) {
        return (
        <div className="headerelement">
            <div className="titlepane" >
                <div className="titletop">
                    <div className="titletext" >
                        Marine & Coastal Dashboard
                    </div>
                    <div className="titlemenu">
                    
                    {
                        //props.loggedIn ? 
                        (typeof props.loggedIn !== 'undefined' && props.loggedIn != null && !isEmpty(props.loggedIn)) ?
                        <IconButton color="primary" onClick={props.signOut} style={{fontSize:'small'}}>Sign out</IconButton>  :
                        <IconButton color="primary" onClick={props.showLogin} style={{fontSize:'small'}}>Sign in </IconButton>
                    }
                       
                    </div>
                </div>
                <div className="titlesubtext" >
                    {props.selectedTab !== null ? props.selectedTab.longname : ""}
                  {/* Beta version | Last updated: {preval`module.exports = new Date().toLocaleDateString("en-IN");`} */}
                </div>                
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
            signOut: () => dispatch(signOut()),
        }
}    


export default connect(null, mapDispatchToProps)(Header);
