import React from 'react';
import SimplePanel from './SimplePanel'

export default function SpatialPanel(props){
    const contents = (!props.skeleton && typeof props.metrics !== 'undefined') ? props.metrics.map((metric) => {
         return (
            <div key={metric.id}>
                <div className="spatialmetric" style={{color:props.fillcolor}}>{metric.value}</div>
                <div className="spatialtext">{metric.desc}</div>
            </div>
        ) 
    }) : 'No data';
    return (
        <SimplePanel title="Spatial information" addStyle={{borderTop: '2px solid '+props.fillcolor}}>
            <div className="spatialcontents">
                {contents}
            </div>
        </SimplePanel>
    )
}
