
import {API} from 'aws-amplify';

export const getRA  = (params) => {
    if(params === null){
        return (dispatch, getState) => {
            dispatch({type : "GET_RA_DATA", CVCA: null})
        }
    }

    return (dispatch, getState) => {
        API.get('raapi', '/ra/?year='+params.yearofstudy+'&cvca_id='+params.cvca_id+'&cvca_name='+params.cvca_name)
        .then(data => {
            //console.log(data);
            dispatch({type : "GET_RA_DATA", data})
        })
        .catch((err) => {
            console.log(" ERROR "+err);
            //dispatch({type:'GET_RA_DATA_ERROR', err})
            console.log("Will retry after 5 seconds")
            setTimeout(function(){ 
                console.log("Retrying")
                API.get('raapi', '/ra/?year='+params.yearofstudy+'&cvca_id='+params.cvca_id+'&cvca_name='+params.cvca_name)
                .then(data => {
                    dispatch({type : "GET_RA_DATA", data})
                })
                .catch((err) => {
                    console.log(" ERROR in retry "+err);
                    dispatch({type:'GET_RA_DATA_ERROR', err})
                })
            }, 5000);
        });
    }
}