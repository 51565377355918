import React from 'react'
import Draggable from 'react-draggable';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ArchiveIcon from '@material-ui/icons/Pages'
import InputLabel from '@material-ui/core/InputLabel';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { Storage } from 'aws-amplify';
import {parseFilePath} from './Utils';

function PaperComponent(props) {
    return (
      <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
}

function AddCVCA(props){
    const [state, setState] = React.useState({
        year: props.year,
        cvca: props.cvca,
        archive: null,
        archiveFileName : "",
        loading: false,
        error: "",
        success: ""
    });

    function handleCancel(){
        setState({
            ...state,
            year: props.year,
            cvca: props.cvca,
            archive: null,
            archiveFileName : "",
            error : "",
            success: ""
        });
        props.close();
    }
/* 
    function handleKeyDown(e){
        if(e.keyCode === 13) {
            handleSubmit();
        }
    } */

    function handleSubmit(){
        if(!validate()){
            setState({...state, 
                error:"Input missing for mandatory fields. Please review.",
                success: "",
                loading: false});
            return;
        }
        else{
            setState({...state, error:"", success: "", loading: true});
            Storage.put('cvca_zipfiles/'+state.year+'_'+state.cvca+'.zip', state.archive, {
                contentType: 'application/zip'
            })
            .catch(err => setState({...state, error : err}))
            .then (result => {
                //console.log(result);
                    setState({...state, 
                        error : "", 
                        success: "Patch dataset added successfully!",
                        archive: null, 
                        archiveFileName : "",
                        loading: false,
                     })
                }
            ) 
        }
    }

    function validate(){
        let value = null;
        for(let key of Object.keys(state)){
            if(!['success', 'error'].includes(key)){
                value = state[key];
                //console.log(value);
                if(value === undefined || value === null || value === ""){
                    return false;
                }
            }            
        }
        return true;
    }

    function handleFileChange(e){
        setState({
            ...state,
            archive : e.target.files[0],
            archiveFileName: parseFilePath(e.target.value, false),
            patch: parseFilePath(e.target.value, true)
        })

        e.target.value = "";
    }

    function handleChange(e){
        setState({
            ...state,
            [e.target.name] : e.target.value
        }) 
    }

/*     function setLoading(boolean){
        setState({...state, loading: boolean});
    } */

    const cvcaoptions = props.cvcas !== undefined && props.cvcas !== null ?
                        props.cvcas.map(cvca => {
                            return (
                                <MenuItem key={cvca.id} value={cvca.id}>{cvca.name}</MenuItem>
                            ) 
                        }) : [];

    return (
        <div>
        <Dialog open={props.open} disableBackdropClick={true}
            onClose={handleCancel} PaperComponent={PaperComponent} >
            <DialogTitle 
                style={{ 
                    cursor: 'move', 
                    borderBottom: '1px groove lightgray' 
                }} id="draggable-dialog-title"><b>Add CVCA</b>
            </DialogTitle>
            <DialogContent>
            <Fade in={state.loading} unmountOnExit style={{
                transitionDelay: state.loading ? '800ms' : '0ms',
            }}>
                <LinearProgress />
            </Fade>
            <div style={{color:'green'}}>{state.success}</div>
            <DialogContentText>
                {state.error}
            </DialogContentText> 
            <form> 
            <div style={{display:'flex', flexDirection:'column', padding:'20px'}} >
            <FormControl required >
                <InputLabel id="year-label">Year</InputLabel>
                <Select id="year" value={state.year} onChange={handleChange} >
                    <MenuItem value={2019}>2019</MenuItem>
                </Select>
            </FormControl>
            <FormControl required >
                <InputLabel id="cvca-label">CVCA</InputLabel>
                <Select id="cvca" name="cvca" value={state.cvca} onChange={handleChange} >
                  {cvcaoptions}
                </Select>
            </FormControl>
            Any existing datasets associated with this CVCA will be overwritten.
            
            <input accept=".zip" style={{ display: 'none' }}  
                id="archive" type="file" onChange={handleFileChange} />
              <label htmlFor="archive">
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', paddingTop:'20px', paddingBottom:'10px', cursor:'pointer'}}>
                    <div>{state.archiveFileName === "" ? 'Upload CVCA Data Archive File*' : state.archiveFileName}</div>
                    <div style={{color:'rgb(63, 81, 181)'}}><ArchiveIcon/></div>
                </div> 
              </label> 
            </div>
            </form>
            </DialogContent>
            <DialogActions>
                <button className="bbutton" onClick={handleCancel} > Close </button>
                <button 
                    className="bbutton" 
                    onClick={handleSubmit} 
                    style={{
                        color:'white', 
                        backgroundColor:'rgb(74, 119, 229)',
                        width:'auto'
                    }}
                > Save </button>
            </DialogActions>
        </Dialog>
        </div>
    )
}

export default AddCVCA;