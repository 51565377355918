import React from 'react';
import EcosystemDetail from './EcosystemDetail';
import WelcomeCard from './WelcomeCard';
import Header from './Header'
import CVCADetail from './CVCADetail';
import NDADetail from './NDADetail';

function DetailPane(props){
    const classcode = (props.selectedTab !== null && props.selectedTab.id === "esa" && props.level !== 0) ?
        "force-scroll" : "none";

    return (
        <div className={classcode} >
            <Header 
                breadcrumb={typeof props.ecoProfile !== 'undefined' ? props.ecoProfile.breadcrumb : null } 
                loggedIn={props.loggedIn} 
                showLogin={props.showLogin} 
                selectedTab={props.selectedTab}
            />
            { props.selectedTab !== null && props.selectedTab.id === "esa" ?
                <EcosystemDetail 
                    key={props.ecoProfile.id} 
                    fillcolor={props.fillcolor} 
                    ecosystemname={props.ecosystemname}
                    level={props.level}
                    breadcrumb={props.ecoProfile.breadcrumb} 
                    loading={props.loading}
                    loggedIn={props.loggedIn} 
                    showFullScreen={props.showFullScreen}
                    fullScreen={props.fullScreen}
                    ecosystemTypes={props.ecosystemTypes} 
                    showLogin={props.showLogin}
                />
            :  props.selectedTab !== null && props.selectedTab.id === "cvca" ? 
                <CVCADetail 
                    loading={props.loading} 
                    loggedIn={props.loggedIn}                     
                    selectRA={props.selectRA}
                    selectedRA={props.selectedRA}
                />
            :  props.selectedTab !== null && props.selectedTab.id === "nda" ? 
                <NDADetail
                    loading={props.loading} 
                    loggedIn={props.loggedIn}      
                    selectedNDA={props.selectedNDA}
                    selectNDA={props.selectNDA}               
                />
            : <WelcomeCard loggedIn={props.loggedIn}/>
            }
        </div>
    )
}

export default DetailPane;