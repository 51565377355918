import React, {useState} from 'react';
import SpatialPanel from './SpatialPanel'
import BiodiversityPanel from './BiodiversityPanel'
import HealthPanel from './HealthPanel'
import FilmStrip from './FilmStrip'
import { connect } from 'react-redux'
import ListPanel from './ListPanel'
import RegionSelectorPanel from './RegionSelectorPanel'
import AddIcon from '@material-ui/icons/AddCircleOutline';
import RefreshIcon from '@material-ui/icons/LoopOutlined';
//import ComputeIcon from '@material-ui/icons/BorderInnerOutlined';
import ComputeIcon from '@material-ui/icons/PlayCircleOutline';
import BugReportIcon from '@material-ui/icons/ErrorOutline';
import { getEco } from '../store/Actions/GetEcoAction'
import { getPatch } from '../store/Actions/GetPatchAction'
import Skeleton from '@material-ui/lab/Skeleton';
import AddPatch from './AddPatch';
import RunFramework from './RunFramework';
import {API} from 'aws-amplify';
import MenuBar from './MenuBar'
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';



function EcosystemDetail(props){
  const [formDisplay, setFormDisplay] = useState(false);
  const [displaySpecies, setDisplaySpecies] = useState(false);
  const [speciesTitle, setSpeciesTitle] = useState("");
  const [speciesList, setSpeciesList] = useState([]);
  const [framework, setFramework] = useState(false);
  const [showAlert, setShowAlert] = React.useState(false);

  function showForm(){
    setFormDisplay(true);
  }

  function hideForm(){
    setFormDisplay(false);
  }

  function showFramework(){
    setFramework(true);
  }

  function downloadBugReport(){
    const myInit = {
      responseType: 'blob',
      response: true
    };
    
    API.get('bugapi', '/bugs/'+props.ecoProfile.id, myInit).then((response) => {
      const blob = new Blob([response.data], { type: 'application/octet-stream' })
      const filename = props.ecoProfile.id+"_errors.csv";
  
      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        window.navigator.msSaveBlob(blob, filename)
      } else {
        const blobURL = window.URL.createObjectURL(blob)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', filename)
  
        if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank')
        }
  
        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        window.URL.revokeObjectURL(blobURL)
      }
    }).catch(error => {
      setShowAlert(true);
    });
  }

  
function closeSnackBar(){
  setShowAlert(false)
}

  function hideFramework(){
    setFramework(false);
  }


  function refresh(){
    //console.log(props.ecoProfile.id);
    const tokenlength = props.ecoProfile.id.split('-').length;
    if(tokenlength === 9){
      props.getPatch({id : props.ecoProfile.id});
    }
    else{
      props.getEco({id : props.ecoProfile.id});
    }
  }

  function getFullList(speciestype, speciesname){
    setDisplaySpecies(true);
    setSpeciesList([]);
    //console.log("props.ecoProfile.level "+props.ecoProfile.level);
    const tokens = props.ecoProfile.id.split("-");
    const ecotype = tokens[1];

    let region = null;

    //console.log(props.ecoProfile.id);
    //console.log(tokens.length);
    if(props.ecoProfile.level === 1){
      region = '';
    }
    else if(props.ecoProfile.level === 2){
      region = tokens[2] + '-'+ tokens[3];
    }
    else if(props.ecoProfile.level === 3){
      region = tokens[2] + '-'+ tokens[3] + '-'+ tokens[4];
    }
    else if(props.ecoProfile.level === 4 && tokens.length > 7){
      region = tokens.slice(6).join("-");
    }
    else{
      region = tokens[2] + '-'+ tokens[3] + '-'+ tokens[4] + '-'+ tokens[5];
    }

    API.get('speciesapi', '/species/?year='+props.ecoProfile.year+
                            '&ecotype='+ecotype+
                            '&level='+(tokens.length > 7 ? 4 : props.ecoProfile.level-1)+
                            '&region='+region+
                            '&speciestype='+speciestype)
        .then(data => {
            setSpeciesTitle(speciesname);
            setSpeciesList(data.species);
        })
        .catch((err) => {
            console.log(" ERROR "+err);
        });
    
  }

  const editPermission = props.loggedIn.signInUserSession !== undefined ? 
                          props.loggedIn.signInUserSession.accessToken.payload["cognito:groups"].includes("dataadmin")
                          : false;
  let topref = React.createRef();
  let speciesref = React.createRef();

  const buttonbar = [];
  if(editPermission) {
    buttonbar.push(<AddIcon key="add" onClick={showForm} style={{color: props.fillcolor, cursor: "pointer",paddingLeft: '10px', marginBottom:'-5px'}} /> );

    if(props.level >= 2 ){
      buttonbar.push(<ComputeIcon key="compute" onClick={showFramework} style={{color: props.fillcolor, cursor: "pointer",paddingLeft: '10px', marginBottom:'-5px'}} />);
      buttonbar.push(<BugReportIcon key="bugreport" onClick={downloadBugReport} style={{color: props.fillcolor, cursor: "pointer",paddingLeft: '10px', marginBottom:'-5px'}} />);
    }
  }
  buttonbar.push(<RefreshIcon key="refresh" onClick={refresh} style={{color: props.fillcolor, cursor: "pointer",paddingLeft: '10px', marginBottom:'-5px'}}/>);

    if(typeof props.ecoProfile !== 'undefined' && 
    typeof props.ecoProfile.id !== 'undefined' ){
        return (
            <div className="ecosystemdetail" key="ecosystemdetail" ref={topref}>
                <div className="stickyheader" key={props.ecoProfile.id}>
                <MenuBar 
                    ecosystemTypes={props.ecosystemTypes} 
                    reset={props.reset} 
                    level={props.level} 
                    loggedIn={props.loggedIn}
                    showLogin={props.showLogin}
                    loading={props.loading}
                    showFullScreen={props.showFullScreen}
                    fillcolor={props.fillcolor} 
                    ecoProfile={props.ecoProfile.id}
                    buttonbar={buttonbar}
                 />
                 <RegionSelectorPanel ecosystem={props.ecosystem} fillcolor={props.fillcolor} parent={props.ecoProfile.parent}
                  regions={props.ecoProfile.regions} key1={props.ecoProfile.id} breadcrumb={props.breadcrumb} 
                  showEco={props.showEco} showState={props.showState} level={props.level} loading={props.loading}
                  showFullScreen={props.showFullScreen} fullScreen={props.fullScreen} hasDataAdmin={editPermission}
                  refresh={refresh}
                />
                </div>              
{/*                   <div className="ecosystemdetailtitle">{props.ecosystemname}</div>
                  {editPermission ? <IconButton color="primary" key="addpatch" onClick={showForm}><AddIcon/></IconButton> : null}
                  {editPermission && props.level >= 2 ? <IconButton color="primary" key="framework" onClick={showFramework}><ComputeIcon/></IconButton> : null}
                  <IconButton color="primary" onClick={refresh}><RefreshIcon/></IconButton>
 */}

                
                <SpatialPanel ecosystem={props.ecosystem} fillcolor={props.fillcolor} 
                  metrics={props.ecoProfile.spatial} 
                />
                
                <BiodiversityPanel ecosystem={props.ecosystem} fillcolor={props.fillcolor} 
                  metrics={props.ecoProfile.biodiversity} speciesref={speciesref} getFullList={getFullList}
                />

                <div ref={speciesref} key="displaySpecies">
                  { displaySpecies ? 
                        speciesList.length > 0 ? 
                        <ListPanel  title={speciesTitle} list={speciesList} key={props.ecoProfile.id}
                          fillcolor={props.fillcolor} scrollToTop={topref} /> 
                        : <Skeleton height={125} width="98%" animation="wave" />
                    : null
                   }
                </div>

                <HealthPanel ecosystem={props.ecosystem} fillcolor={props.fillcolor} 
                  metrics={props.ecoProfile.health} 
                />
             
                  <FilmStrip  
                      id={props.ecoProfile.id}
                      ecosystem={props.ecosystem} 
                      fillcolor={props.fillcolor} 
                  /> 
              
                
                {  editPermission ? <AddPatch open={formDisplay} close={hideForm} key="addpatch"
                selectors={props.ecoProfile.id} level={props.ecoProfile.level}/> : null }

                {  editPermission ? <RunFramework open={framework} close={hideFramework} key="framework"
                selectors={props.ecoProfile.id} /> : null }

              <Snackbar 
                      open={showAlert} anchorOrigin={{ vertical:'top', horizontal:'left' }} 
                      autoHideDuration={3000} onClose={closeSnackBar}
                      message={"No errors in the current region/patch"} 
                      action={
                          <IconButton size="small" aria-label="close" color="inherit" onClick={closeSnackBar}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                      }
              />
            </div>
          )
        }
        else{
          return (
            <div className="ecosystemdetail">
              <MenuBar 
                    ecosystemTypes={props.ecosystemTypes} 
                    reset={props.reset} 
                    level={props.level} 
                    loggedIn={props.loggedIn}
                    showLogin={props.showLogin}
                    loading={props.loading}
                    showFullScreen={props.showFullScreen}
                 />

            </div>
          )
        }
}
const mapDispatchToProps = (dispatch) => {
  return {
          getEco: (params) => dispatch(getEco(params)),
          getPatch: (params) => dispatch(getPatch(params)),
      }
}   
 const mapStateToProps = state => {
    return {
      ecoProfile: state.getEco.EcoProfile,
    }
  } 

export default connect(mapStateToProps, mapDispatchToProps)(EcosystemDetail);
