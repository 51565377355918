import React from 'react';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import {getColor} from './Utils';

function CategorySlider(props){
    const [color, setColor] = React.useState(getColor(props.value));
    const [val, setVal] = React.useState(props.value);

    const PrettoSlider = withStyles({
        root: {
          color: color,
          height: 10,
        },
        thumb: {
          height: 24,
          width: 24,
          backgroundColor: '#fff',
          border: '2px solid currentColor',
          marginTop: -8,
          marginLeft: -12,
          '&:focus,&:hover,&$active': {
            boxShadow: 'inherit',
          },
        },
        active: {},
        valueLabel: {
          left: 'calc(-50% + 4px)',
        },
        track: {
          height: 10,
          borderRadius: 4,
        },
        rail: {
          height: 10,
          borderRadius: 4,
        },
      })(Slider);


    function handleChange(e, v){
        setColor(getColor(v));
        setVal(v);
        return true;
    }
    return ( 
        <div style={{
            display:'flex', 
            flexDirection:'row', 
            justifyContent:'space-between',
            alignItems:'flex-end'}}>
        <div style={{display:'flex', flexDirection:'column', width:'80%'}}>
            <div>{props.name}</div>
                <PrettoSlider 
                    valueLabelDisplay="off" 
                    min={0}
                    max={100}
                    defaultValue={val}
                    onChangeCommitted={handleChange}
                />
        </div>
            <div style={{color:color, padding: '10px', fontSize:'larger'}}>
                {val}%
            </div>
        </div>
    )
}

export default CategorySlider

