import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: 'rgb(117, 207, 240, 1)',
    
  },
}));

export default function SimpleBackdrop(props) {
  const classes = useStyles();
  const msg = props.backdrop ?  
    <div style={{
      textAlign:'center', 
      width:'100%', 
      padding:'10px', 
      fontSize:'large',
    }}>
      <h2>Coastal & Marine Dashboard</h2>
      Loading.....<br/><br/>
      <CircularProgress/>
    </div> : "";

  //const bgColor = props.backdrop ? 'rgb(76, 37, 162, 1)' : 'transparent';
  const bgColor = 'rgb(76, 37, 162, 1)';

  return (
    <div>
      <Backdrop className={classes.backdrop} style={{backgroundColor:bgColor}} open={props.backdrop} >
        {msg}
      <div className="ocean">
        <div className="wave"></div>
        <div className="wave"></div>
      </div>
      </Backdrop>
    </div>
  );
}