const initialState = {
    Error: null,
    CVCA: {}
}

const GetRAReducer = (state = initialState, action) => {
    switch(action.type){
        case 'GET_RA_DATA':
            const newstate = {...state, Error:'', CVCA: action.data};
            return newstate

        case 'GET_RA_DATA_ERROR':
            return {...state, Error: 'Fetch Failed'}

        default:
           return state  
    }
}

export default GetRAReducer