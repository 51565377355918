
import React from 'react';
import SimplePanel from './SimplePanel';
import { connect } from 'react-redux'
import { getEco } from '../store/Actions/GetEcoAction'
import { getPatch } from '../store/Actions/GetPatchAction'
import {getAncestors, getS3FolderPath} from './Utils'
//import UpIcon from '@material-ui/icons/SubdirectoryArrowLeft'
import Chip from '@material-ui/core/Chip'
import CancelIcon from '@material-ui/icons/Cancel'
import {API} from 'aws-amplify';
import {Storage} from 'aws-amplify';
import ConfirmDialog from './ConfirmDialog'


function RegionSelectorPanel(props){
    const [confirm, setConfirm] = React.useState(false);
    const [deletePatch, setDeletePatch] = React.useState(null);
    
    function handleClick(id){
        if(props.level === 4){
            if(props.key1 !== id){
                props.loading(true);
            }
            //props.showFullScreen("none");
            props.getPatch({id : id});
        }
        else{
            props.loading(true);
            props.getEco({id : id});
            if(props.fullScreen === "Thresholds"){
                props.showFullScreen("none");
            }
            //props.showFullScreen("none");
        }

    }

    function handleClickBreadCrumb(id){
        props.loading(true);
        props.getEco({id : id});
        //props.showFullScreen("none");
        if(props.fullScreen === "Thresholds"){
            props.showFullScreen("none");
        }
    }

    function delPatch(){
        setConfirm(false);
        props.loading(true);
        const path = getS3FolderPath(deletePatch.id);
        console.log('images/'+path);
        console.log('geo/'+path.substring(0, path.length-1)+'.geojson');
        
        Storage.list('images/'+path, { level: 'public' })
        .then(results => {
            results.forEach((result, i) => {
                if(i > 0){
                    console.log(result.key);
                    Storage.remove(result.key)
                    .then(res => console.log(res))
                    .catch(err => console.log(err))
                }
            })
        })
        .catch(err => console.log(err)); 

        Storage.remove('geo/'+path.substring(0, path.length-1)+'.geojson')
        .then(res => console.log(res))
        .catch(err => console.log(err))
        
        API.del('patchapi', '/patches/'+deletePatch.id)
        .then(data => {
           console.log(data);
           props.loading(false);
           props.refresh();
        })
        .catch((err) => {
            console.log(" ERROR "+err);
            props.loading(false);
            props.refresh();
        });         
    }

    function handleDelete(region){
        console.log("Handle delete for id: "+region.id);
        setConfirm(true);
        setDeletePatch(region);
    }

    function closeConfirm(){
        setConfirm(false);
    }
/*
    function goUP(){
        props.loading(true);
        props.getEco({id: props.parent});
        if(props.fullScreen === "Thresholds"){
            props.showFullScreen("none");
        }
    }
*/
    const regionList = 
        (props.level === 4 && props.hasDataAdmin === true) ? 

        (typeof props.regions !== 'undefined') ? props.regions.map((region) => {
            if(props.key1 === region.id){
                return (
                    <Chip 
                        style={{backgroundColor:props.fillcolor, color:'white', border:'1px solid '+props.fillcolor, borderRadius: '5px', margin: '5px', fontWeight: 'bold'}}
                        color="secondary" id={region.id} key={region.id} size="small" label={region.name} 
                        onClick={() => handleClick(region.id)}
                    />
                )
            }
            else{
                return (
                    <Chip 
                        style={props.key1 === region.id ? 
                            {backgroundColor:props.fillcolor, color:'white', border:'1px solid '+props.fillcolor, borderRadius: '5px', margin: '5px', fontWeight: 'bold'} : 
                            {color:props.fillcolor, backgroundColor:'white', border:'1px solid '+props.fillcolor, borderRadius: '5px', margin: '5px', fontWeight: 'bold'}
                        }
                        color="secondary"
                        id={region.id} key={region.id} size="small" label={region.name} 
                        onClick={() => handleClick(region.id)} variant={'outlined'} 
                        onDelete={() => handleDelete(region)}
                        deleteIcon={<CancelIcon style={props.key1 === region.id ? {color: 'white'} : {color: props.fillcolor}} />}
                    />
                )
            }

        }) : null
        : 
        (typeof props.regions !== 'undefined') ? props.regions.map((region) => {
        return (
            <button id={region.id} key={region.id} className="bbutton" 
            style={props.key1 === region.id ? {color:'white', backgroundColor: props.fillcolor, fontWeight: 'bold' } : {color: props.fillcolor, backgroundColor: 'white', border:'1px solid '+props.fillcolor, fontWeight: 'bold' }} 
            onClick={() => handleClick(region.id)} >{region.name}</button>

        )
    }) : null;

    const breadcrumbs = props.breadcrumb.split(">");
    const breadcrumbTarget = getAncestors(props.parent);

    const breadcrumbBtns = breadcrumbs.map((breadcrumb, i) => {
        if(i === breadcrumbs.length - 1 && breadcrumbTarget.length !== 4){
            return breadcrumb;
        }
        else{
            return (
                [<button id={breadcrumb} key={breadcrumb} className="breadcrumbbutton" 
                style={{color: props.fillcolor, backgroundColor: 'white', fontWeight: 'bold', padding: 0, border: 0 }}
                onClick={() => handleClickBreadCrumb(breadcrumbTarget[i])} >{breadcrumb}</button>, '> ']
            )
        }
    });

    return (
        [<SimplePanel key="regions" title={breadcrumbBtns} addStyle={{borderTop: '2px solid '+props.fillcolor, width:'98%'}}>
            <div className="regionpanelbuttonbar" style={{alignItems:'center'}}>
                {/* props.parent !== null ? //typeof props.key1 != 'undefined' && props.key1.split('-').length > 1 ? 
                    <UpIcon 
                        onClick={goUP} 
                        style={{
                            color:props.fillcolor, 
                            margin: '5px',
                            transform: 'rotate(90deg)',
                            cursor: 'pointer'
                        }} 
                    />
                    : null */}
                {regionList}
            </div>
        </SimplePanel>,
        <ConfirmDialog 
            key="confirm"
            open={confirm} 
            title="Delete Patch" 
            content={"Are you sure you want to delete this patch?"} 
            patch={deletePatch === null ? "" : deletePatch.name}
            cancel="No" 
            submit="Yes" 
            close={closeConfirm}
            handleSubmit={delPatch}
        />]
    )
}


const mapDispatchToProps = (dispatch) => {
    return {
            getEco: (params) => dispatch(getEco(params)),
            getPatch: (params) => dispatch(getPatch(params)),
        }
}    

export default connect(null, mapDispatchToProps)(RegionSelectorPanel);
