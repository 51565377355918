import React from 'react';
export default function SimplePanel(props){
    return (
        <div className="simplepanel" style={props.addStyle}>
            <div className="paneltitle">
                <div>{props.title}</div>
                <div>{props.buttons}</div>
            </div>
            <div className="panelcontent" style={props.addContentStyle}>
                {props.children}
            </div>
        </div>
    )
}