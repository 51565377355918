import React from 'react';
import SimplePanel from './SimplePanel'
import { S3Image } from 'aws-amplify-react';
import Previous from '@material-ui/icons/KeyboardArrowLeft';
import Next from '@material-ui/icons/KeyboardArrowRight';
import {shuffle, getS3FolderPath} from './Utils';
//import { API } from 'aws-amplify';
import { Storage } from 'aws-amplify';
import Skeleton from '@material-ui/lab/Skeleton';


function FilmStrip(props){
    const [activeStep, setActiveStep] = React.useState(0);
    const [images, setImages] = React.useState(null);
    const [maxSteps, setMaxSteps] = React.useState(0);
    const [loading, setLoading] = React.useState(true);

    const handleLoad = () => {
        setLoading(false);
    }
    
    React.useEffect(() => {
       Storage.list('images/'+getS3FolderPath(props.id), { level: 'public' })
       .then(result => {
           setImages(shuffle(result));
           setMaxSteps(result.length);
       })
       .catch(err => console.log(err)); 
    }, [props.id]);


    const handleNext = () => {
        setLoading(true);
        setActiveStep(prevActiveStep => (prevActiveStep === maxSteps-1) ? 0 : prevActiveStep + 1);
    };
    
    const handleBack = () => {
        setLoading(true);
        setActiveStep(prevActiveStep => (prevActiveStep === 0) ? maxSteps-1 : prevActiveStep - 1);
    };

    const buttons = <div className="listpanelbuttonbar" style={{justify:'center'}}>
                        <Previous onClick={handleBack} style={{margin: '5px',cursor:'pointer',color: props.fillcolor}}/>
                        <div>{maxSteps === 0 ? 0 : activeStep+1}/{maxSteps}</div>
                        <Next onClick={handleNext} style={{margin: '5px',cursor:'pointer',color: props.fillcolor}}/>
                    </div>;
    
        let orientation = 0;
    
    if(images != null && images.length > 0 && typeof images[activeStep] !== 'undefined') {
        const dotindex = images[activeStep].key.lastIndexOf('.');
        const dashindex = images[activeStep].key.lastIndexOf('-');
        
        orientation = parseInt(images[activeStep].key.substring(dashindex+1, dotindex));
        //console.log(orientation); 

        if(isNaN(orientation)){
            orientation = 1;
        }
    }
    
    if(images !== null && images.length > 0){
        return (
            <SimplePanel title="Field images" 
                addStyle={orientation === 8 ? {borderTop: '2px solid '+props.fillcolor, height: '70vh'} : {borderTop: '2px solid '+props.fillcolor}}
                addContentStyle={orientation === 8 ? 
                    {display: 'flex', justifyContent: 'center', alignItems:'center', width:'100%', flexDirection:'column'} : 
                    {display: 'flex', justifyContent: 'center', alignItems:'center', flexDirection:'column'} 
                }
                buttons={buttons}
            >
                { loading ? 
                    (orientation === 8 || orientation === 0) ? <Skeleton height="70vh" width="80%" animation="wave" />
                    : <Skeleton height="400px" width="100%" animation="wave" />
                    : null
                }
                <div style={loading ? {display:'none'} : {display:'block'}} >
                <S3Image level="public" onLoad={handleLoad}
                key={images[activeStep].key} imgKey={images[activeStep].key} 
                theme={(orientation === 1) ? { photoImg: { width: '100%'} } : { photoImg: { width: '100%', transform: 'rotate(270deg)'} }}
                />
                </div>
    
            </SimplePanel>
        )
    }
    else{
        return null;
    }    

}



export default FilmStrip;