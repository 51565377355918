function getColor(val){
    if(typeof val === 'undefined' || val < 0){
        return 'gray';
    }
    else if(val <= 20 ){
        return 'rgb(221,42,27)';

    }
    else if(val <= 40 ){
        return 'rgb(241,126,0)';

    }
    else if(val <= 60){
        return 'rgb(251,188,7)';
    }
    else if(val <= 80){
        return 'rgb(118, 194, 0)';

    }
    else{
        return 'rgb(19, 111, 6)';
    }
}

function getSunburstData(firstLevelData, secondLevelData){ 
    return [{
        name: 'Resilience &\nFunction',
        value:4,
        itemStyle: {
            color: getColor(firstLevelData[0])
        },
        children: [{
            name: 'Habitat diversity',
            value: 0.80,
            itemStyle: {
                color: getColor(secondLevelData[0][0])
            }
        },{
            name: 'Site adequacy',
            value: 0.80,
            itemStyle: {
                color: getColor(secondLevelData[0][1])
            }
        },{
            name: 'Ecosystem\ncontiguity',
            value: 0.80,
            itemStyle: {
                color: getColor(secondLevelData[0][2])
            }
        },{
            name: 'Freshwater\ndischarge',
            value: 0.80,
            itemStyle: {
                color: getColor(secondLevelData[0][3])
            }
        },{
            name: 'Erosion control &\nNatural protection',
            value: 0.80,
            itemStyle: {
                color: getColor(secondLevelData[0][4])
            }
        }]
    },
    {
        name: '  Socio-\ndemographics',
        value:4,
        itemStyle: {
            color: getColor(firstLevelData[1])
        },
        children: [{
            name: 'Socio-cultural\nvalue',
            value: 1.3333,
            itemStyle: {
                color: getColor(secondLevelData[1][0])
            }
        },{
            name: 'Socio-economic\npotential',
            value: 1.3333,
            itemStyle: {
                color: getColor(secondLevelData[1][1])
            }
        },{
            name: 'Degree of stress',
            value: 1.3334,       
            itemStyle: {
                color: getColor(secondLevelData[1][2])
            }
        }]
    },
    {
        name: 'Biodiversity\n& Habitat',
        value:4,
        itemStyle: {
            color: getColor(firstLevelData[2])
        },
        children: [{
            name: 'Uniqueness of\nrarity',
            value: 1,
            itemStyle: {
                color: getColor(secondLevelData[2][0])
            }
        },{
            name: 'Biological diversity',
            value: 1,
            itemStyle: {
                color: getColor(secondLevelData[2][1])
            }
        },{
            name: 'Importance to\nthreatened species',
            value: 1,
            itemStyle: {
                color: getColor(secondLevelData[2][2])
            }
        },{
            name: 'Importance of life\nhistory changes',
            value: 1,
            itemStyle: {
                color: getColor(secondLevelData[2][3])
            }
        }]
    },
    {
        name: 'Ecosystem\nHealth',
        value:4,
        itemStyle: {
            color: getColor(firstLevelData[3])
        },
        children: [{
            name: 'Benthic substrate\ncomposition',
            value: 1.33,
            itemStyle: {
                color: getColor(secondLevelData[3][0])
            }
        },{
            name: 'Biological health\nindicator',
            value: 1.33,
            itemStyle: {
                color: getColor(secondLevelData[3][1])
            }
        },{
            name: 'Biodiversity indices',
            value: 1.33,
            itemStyle: {
                color: getColor(secondLevelData[3][2])
            }
        }]
    }
    ];

}

function getCVCASunburst(ra){ 
    return [
        {
            name: 'Resource\nDependency',
            value:3,
            itemStyle: {
                color: getColor(ra.resource_dependency)
            },
            children: [{
                name: 'Resource Use',
                value: 1.5,
                itemStyle: {
                    color: getColor(ra.resource_use_index)
                }
            },{
                name: 'Resource Use\nPerception',
                value: 1.5,
                itemStyle: {
                    color: getColor(ra.resource_use_perception_index)
                }
            }]
        },{
        name: 'Governance\nAbility',
        value:4,
        itemStyle: {
            color: getColor(ra.governance_ability)
        },
        children: [{
            name: 'Conservation\nPerception',
            value: 1,
            itemStyle: {
                color: getColor(ra.conservation_attitude)
            }
        },{
            name: 'Management\nPerception',
            value: 1,
            itemStyle: {
                color: getColor(ra.management_perception)
            }
        },{
            name: 'Willingness to\nParticipate',
            value: 1,       
            itemStyle: {
                color: getColor(ra.willingness_to_participate)
            }
        },{
            name: 'Community\nParticipation',
            value: 1,       
            itemStyle: {
                color: getColor(ra.community_participation)
            }
        }]
    }
    ];
}

function getCategoryNames(){
    return ["Resilience & Function", "Socio-demographics", 
            "Biodiversity & Habitat", "Ecosystem Health"];
}

function isEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
  }
  
function parseFilePath(filepath, withExtension){
    //const index = filepath.lastIndexOf(filepath, '\\');
    //if(index !== -1 ){
        //filepath = filepath.substring(index);
    //}

    filepath = filepath.replace(/^.*[\\/]/, '');
    filepath = filepath.replace(/_/g, '-');

    if(withExtension){
        const index1 = filepath.lastIndexOf('.');
        if(index1 !== -1){
            filepath = filepath.substring(0, index1);
        }
    }

    return filepath;
}

function shuffle(array) {
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
}

function getAncestors(str){
    if(str === null){
        return [];
    }

    var tokens = str.split("-");
    if(tokens.length === 3){
        return [tokens[0] + '-' + tokens[1]+ '-' + tokens[2]];
    }
    else if(tokens.length === 4){
        return [tokens[0] + '-' + tokens[1] + '-' + tokens[2],
                tokens[0] + '-' + tokens[1] + '-' + tokens[2] + '-' + tokens[3]
            ];
    }
    else if(tokens.length === 5){
        return [tokens[0] + '-' + tokens[1] + '-' + tokens[2], 
                tokens[0] + '-' + tokens[1] + '-' + tokens[2] + '-' + tokens[3], 
                tokens[0] + '-' + tokens[1] + '-' + tokens[2] + '-' + tokens[3] + '-' + tokens[4]
            ];
    }
    else if(tokens.length === 6){
        return [tokens[0] + '-' + tokens[1] + '-' + tokens[2], 
                tokens[0] + '-' + tokens[1] + '-' + tokens[2] + '-' + tokens[3], 
                tokens[0] + '-' + tokens[1] + '-' + tokens[2] + '-' + tokens[3] + '-' + tokens[4], 
                tokens[0] + '-' + tokens[1] + '-' + tokens[2] + '-' + tokens[3] + '-' + tokens[4] + '-' + tokens[5], 
            ];
    }
}

function getS3FolderPath(str) {
    var tokens = str.split("-");
    if(tokens.length === 3){
        return tokens[0] + '/' + tokens[1] + '/';
    }

    else if(tokens.length === 4){
        return tokens[0] + '/' + tokens[1] + '/' + tokens[2] + '-' + tokens[3] + '/';
    }

    else if(tokens.length === 5){
        return tokens[0] + '/' + tokens[1] + '/' + tokens[2] + '-' + tokens[3] + '/' + 
                tokens[2] + '-' + tokens[3] + '-' + tokens[4] + '/' ; 
    }
    else if(tokens.length === 6){
        return tokens[0] + '/' + tokens[1] + '/' + tokens[2] + '-' + tokens[3] + '/' + 
                tokens[2] + '-' + tokens[3] + '-' + tokens[4] + '/' +  
                tokens[2] + '-' + tokens[3] + '-' + tokens[4] + '-' + tokens[5] + '/';
    }
    else {
        return tokens[0] + '/' + tokens[1] + '/' + tokens[2] + '-' + tokens[3] + '/' + 
                tokens[2] + '-' + tokens[3] + '-' + tokens[4] + '/' + 
                tokens[2] + '-' + tokens[3] + '-' + tokens[4] + '-' + tokens[5] + '/' + 
                tokens.slice(6).join('-') + '/';
    }
}
  

export {getColor, getSunburstData, getCategoryNames, isEmpty, parseFilePath, shuffle, getS3FolderPath, getCVCASunburst, getAncestors};