
import {API} from 'aws-amplify';

export const getPatch  = (params) => {
    return (dispatch, getState) => {
        API.get('patchapi', '/patches/'+params.id)
        .then(data => {
            //console.log(JSON.stringify(data));
            dispatch({type : "GET_DATA", data})
        })
        .catch((err) => {
            console.log(" ERROR "+err);
            //dispatch({type:'GET_PATCH_DATA_ERROR', err})
            console.log("Will retry after 5 seconds")
                setTimeout(function(){ 
                    console.log("Retrying")
                    API.get('patchapi', '/patches/'+params.id)
                    .then(data => {
                        dispatch({type : "GET_DATA", data})
                    })
                    .catch((err) => {
                        console.log(" ERROR in retry "+err);
                        dispatch({type:'GET_PATCH_DATA_ERROR', err})
                    })
                }, 5000);
        });
    }
}

/*
export const getPatch  = (params) => {
    return (dispatch, getState) => {
        API.get('ecosystemsapi', '/ecosystems/'+params.id.split('-').slice(0, 6).join("-"))
        .then(data => {
            //testcode - start
            API.get('patchapi', '/patches/'+params.id)
            .then(patchdata => {

                console.log(patchdata[0].framework);

                data[0].spatial = patchdata[0].spatial;
                data[0].parent = data[0].id;
                data[0].id = patchdata[0].id;
                //data[0].level = 5;
                data[0].framework = patchdata[0].framework;
                data[0].biodiversity = patchdata[0].biodiversity;
                data[0].health = patchdata[0].health;

                dispatch({type : "GET_DATA", data})
            })
            //test code - end
        })
        .catch((err) => {
            console.log(" ERROR "+err);
            dispatch({type:'GET_PATCH_DATA_ERROR', err})
        });

    }
}
*/



/*
        API.get('patchapi', '/patches/'+params.id)
        .then(data => {
            console.log(JSON.stringify(data));
            dispatch({type : "GET_PATCH_DATA", data})
        })
        .catch((err) => {
            console.log(" ERROR "+err);
            dispatch({type:'GET_PATCH_DATA_ERROR', err})
        });
    }
*/