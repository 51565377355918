
import React from 'react';
import { connect } from 'react-redux'
//import { getEcoProfile } from '../store/Actions/GetEcosystemProfileAction'
import { getEco } from '../store/Actions/GetEcoAction'
import {isEmpty} from './Utils';


function MenuItem(props){
    function handleClick(event){
        const id = event.target.id;
        if(typeof props.loggedIn !== 'undefined' && props.loggedIn != null && !isEmpty(props.loggedIn)) 
        {
            props.loading(true);
            props.showFullScreen('none');
            props.getEco({id : id});
        }
        else{
            props.showLogin();
        }
    }

    return (
        <button id={props.id}
        className={props.selected ? 'menuitemselected' : 'menuitem'} 
        onClick={handleClick}>
            <div className="menuItemImg" id={props.id}>
                <img className="buttonimg" src={props.img} alt={props.text} width='40' id={props.id} />
            </div>
            <div className="menuItemText" id={props.id}>
                {props.text}
            </div>
        </button> 
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
            //getEcoProfile: (params) => dispatch(getEcoProfile(params)),
            getEco: (params) => dispatch(getEco(params)),
        }
}    

export default connect(null, mapDispatchToProps)(MenuItem);