const initialState = {
    Error: null,
    PatchProfile: {}
}

const GetPatchReducer = (state = initialState, action) => {
    switch(action.type){
        case 'GET_PATCH_DATA':
            const newstate = {...state, Error:'', PatchProfile: action.data[0]};
            return newstate

        case 'GET_PATCH_DATA_ERROR':
            return {...state, Error: 'Fetch Failed'}

        default:
           return state  
    }
}

export default GetPatchReducer