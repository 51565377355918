import React from 'react'
import Draggable from 'react-draggable';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ArchiveIcon from '@material-ui/icons/Pages'
//import SheetIcon from '@material-ui/icons/GridOn'
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
//import Button from '@material-ui/core/Button';
import RegionSelect from './RegionSelect';
import { Storage } from 'aws-amplify';
import {parseFilePath} from './Utils';

function PaperComponent(props) {
    return (
      <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
}

function AddPatch(props){
    const selectors = typeof props.level !== 'undefined' && props.level > 0 ? props.selectors.split('-') : [];

    const [state, setState] = React.useState({
        year: typeof props.level === 'undefined' || props.level <= 0 ? 2019 : parseInt(selectors[0]),
        ecosystem: typeof props.level === 'undefined' || props.level === 0 ? "" : selectors[1],
        state: props.level >= 2  ? selectors.slice(2,4).join("-") : "",
        district: props.level >= 3 ? selectors.slice(2, 5).join("-") : "",
        taluk: props.level >= 4 ? selectors.slice(2, 6).join("-") : "",
        patch: "",
        overwrite: true,
        archive: null,
        archiveFileName : "",
        loading: false,
        error: "",
        success: ""
    });

    function handleCancel(){
        setState({
            ...state,
            year: 2019,
            ecosystem: "",
            state: "",
            district: "",
            taluk: "",
            overwrite: true,
            patch: "",
            archive : null, 
            archiveFileName : "",
            error : "",
            success: ""
        });
        props.close();
    }

    function handleKeyDown(e){
        if(e.keyCode === 13) {
            handleSubmit();
        }
    }

    function handleSubmit(){
        if(!validate()){
            setState({...state, 
                error:"Input missing for mandatory fields. Please review.",
                success: "",
                loading: false});
            return;
        }
        else{
            setState({...state, error:"", success: "", loading: true});
        }
        Storage.put('zipfiles/'+state.year+'_'+state.ecosystem+'_'+state.taluk+'_'+state.archiveFileName, state.archive, {
            contentType: 'application/zip'
        })
        .catch(err => setState({...state, error : err}))
        .then (result => {
                setState({...state, 
                    error : "", 
                    success: "Patch dataset added successfully!",
                    archive: null, 
                    archiveFileName : "",
                    loading: false,
                    patch : ""
                 })
            }
        )
    }

    function validate(){
        let value = null;
        for(let key of Object.keys(state)){
            if(!['success', 'error'].includes(key)){
                value = state[key];
                if(value === null || value === ""){
                    return false;
                }
            }            
        }
        return true;
    }

    function handleFileChange(e){
        setState({
            ...state,
            archive : e.target.files[0],
            archiveFileName: parseFilePath(e.target.value, false),
            patch: parseFilePath(e.target.value, true)
        })

        e.target.value = "";
    }

    function handleChange(e){
        if(e.target.name === "overwrite"){
            setState({
                ...state,
                "overwrite" : e.target.checked
            })
        }
        else{
            setState({
                ...state,
                [e.target.name] : e.target.value
            }) 
        }
    }

    function setLoading(boolean){
        setState({...state, loading: boolean});
    }

    return (
        <div>
        <Dialog open={props.open} disableBackdropClick={true}
            onClose={handleCancel} PaperComponent={PaperComponent} >
            <DialogTitle 
                style={{ 
                    cursor: 'move', 
                    borderBottom: '1px groove lightgray' 
                }} id="draggable-dialog-title"><b>Add Patch</b>
            </DialogTitle>
            <DialogContent>
            <Fade in={state.loading} unmountOnExit style={{
                transitionDelay: state.loading ? '800ms' : '0ms',
            }}>
                <LinearProgress />
            </Fade>
            <div style={{color:'green'}}>{state.success}</div>
            <DialogContentText>
                {state.error}
            </DialogContentText> 
            <form> 
            <div style={{display:'flex', flexDirection:'column', padding:'20px'}} >
            <FormControl required >
                <InputLabel id="year-label">Year</InputLabel>
                <Select id="year" value={state.year} onChange={handleChange} >
                    <MenuItem value={2019}>2019</MenuItem>
                </Select>
            </FormControl>
            <FormControl required >
                <InputLabel id="eco-label">Ecosystem Type</InputLabel>
                <Select id="ecosystem" name="ecosystem" value={state.ecosystem} onChange={handleChange} >
                    <MenuItem value="man">Mangroves</MenuItem>
                    <MenuItem value="cor" >Corals</MenuItem>
                    <MenuItem value="sea">Seagrass</MenuItem>
                    <MenuItem value="sal">Saltmarshes</MenuItem>
                </Select>
            </FormControl>
            
            <RegionSelect 
                required={true} handleChange={handleChange} key="state"
                id="state" label="State" value={state.state} queryvalue="in"
                setLoading={setLoading}
            />

            <RegionSelect 
                required={true} handleChange={handleChange} key={`distfor${state.state}`}
                id="district" label="District" value={state.district} queryvalue={state.state}
                setLoading={setLoading}
            />

            <RegionSelect 
                required={true} handleChange={handleChange} key={`talukfor${state.district}`}
                id="taluk" label="Sub-district" value={state.taluk} queryvalue={state.district}
                setLoading={setLoading}
            />  

            <input accept=".zip" style={{ display: 'none' }}  
                id="archive" type="file" onChange={handleFileChange} />
              <label htmlFor="archive">
                <div style={{display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'space-between', paddingTop:'20px', paddingBottom:'10px', cursor:'pointer'}}>
                    <div>{state.archiveFileName === "" ? 'Upload Patch Data Archive File*' : state.archiveFileName}</div>
                    <div style={{color:'rgb(63, 81, 181)'}}><ArchiveIcon/></div>
                </div> 
              </label> 

            <TextField id="patch" name="patch" label="Patch ID" type="text"  required autoComplete="off"              
            onChange={handleChange} onKeyDown={handleKeyDown} value={state.patch}  />

          {/*   <FormControlLabel control={
                    <Checkbox id="overwrite" name="overwrite" checked={state.overwrite} onChange={handleChange}
                        value="state.overwrite" color="primary" />
                }
                label="Overwrite existing datasets with the same patch id"
            />  */}
            Any existing datasets associated with this Patch ID will be overwritten.

            </div>
            </form>
            </DialogContent>
            <DialogActions>
                <button className="bbutton" onClick={handleCancel} > Close </button>
                <button 
                    className="bbutton" 
                    disabled={!state.overwrite}
                    onClick={handleSubmit} 
                    style={state.overwrite ? {
                        color:'white', 
                        backgroundColor:'rgb(74, 119, 229)',
                        width:'auto'
                    }: null}
                > Save </button>
            </DialogActions>
        </Dialog>
        </div>
    )
}

export default AddPatch;