import React from 'react';
import ncscm from '../NCSCM.jpg'
import wb from '../worldbank.svg'
//import AddIcon from '@material-ui/icons/AddCircleOutline';
//import IconButton from '@material-ui/core/IconButton';
//import RefreshIcon from '@material-ui/icons/LoopOutlined';
//import ComputeIcon from '@material-ui/icons/EqualizerRounded';
import AddPatch from './AddPatch'

function WelcomeCard(props){

    const [formDisplay, setFormDisplay] = React.useState(false);
/*     function showForm(){
        setFormDisplay(true);
    } */

    function hideForm(){
        setFormDisplay(false);
    }

    const editPermission = typeof props.loggedIn.signInUserSession !== 'undefined' && 
                                    props.loggedIn.signInUserSession !== null && 
                        typeof props.loggedIn.signInUserSession.accessToken !== 'undefined' &&
                        typeof props.loggedIn.signInUserSession.accessToken.payload["cognito:groups"] !== 'undefined'  ? 
        props.loggedIn.signInUserSession.accessToken.payload["cognito:groups"].includes("dataadmin")
        : false;

    return (
            
            <div style={{display:'flex', flexDirection:'column', height:'90vh', justifyContent:'flex-start'}}>
              <div className="stickyheader">
                <div className="ecosystemdetailtitle">
                    Introduction
                </div>
                <div style={{paddingRight:'5%'}}>

                </div>
            </div>
            <p style={{width: '95%'}} align='justify'>Marine & Coastal Dashboard is designed to get an aggregate view of all coastal ecosystems in India.<br/><br/>
                This online tool organizes ecosystems according to the ecosystem type and the administrative 
                regions they are part of. It provides a map-based visualization for the ecosystems, along with their high level descriptive 
                statistics, references from various current and historic ecological studies conducted and 
                details of species each ecosystem is habitat for. <br/><br/>

                In addition to these, the dashboard provides a framework for evaluating the health of each ecosystem
                based on various ecosystem parameters such as patch size, socio-demographic settings, biodiversity and ecosystem resilience. 
                This can help policy makers identify critically vulnerable and highly stressed coastal zones in India 
                so that appropriate policies could be formulated for their protection and restoration.<br/><br/>

                <br/><br/><b>Project sponsors</b>
                </p>
                <div className='orgrefer'>
                    <div >
                        <img src={ncscm} alt="NCSCM" height="50px" />
                    </div>
                    <div style={{color:'#00a0e3', fontFamily:'Arial', wrap:true, padding:'10px', fontWeight:'700'}}>
                    NATIONAL CENTRE FOR SUSTAINABLE COASTAL MANAGEMENT
                    </div>
                </div>
                <div className='orgrefer'>
                    <div >
                        <img src={wb} alt="The World Bank" height="50px" style={{paddingTop:'20px'}} />
                    </div>
                </div><br/><br/><br/>
                <div style={{fontSize:'small', marginBottom: '5px', flexGrow:4, display:'flex', flexDirection:'column-reverse'}}>
                    &copy; 2019 National Centre for Sustainable Coastal Management. All rights reserved. 
                </div>
                { editPermission ? <AddPatch open={formDisplay} close={hideForm} /> : null }
                
            </div>    
    )
}

export default WelcomeCard;