const initialState = {
    Error: null,
    EcoProfile: []
}

const GetEcosystemProfileReducer = (state = initialState, action) => {
    switch(action.type){
        case 'GET_DATA':
            const newstate = {...state, Error:'', EcoProfile: action.data};
            return newstate

        case 'GET_DATA_ERROR':
            console.log("Fetch ecosystem data failed")
            return {...state, Error: 'Fetch Failed'}

        default:
           return state  
    }
}

export default GetEcosystemProfileReducer