import React from 'react'
import Draggable from 'react-draggable';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import Slider from '@material-ui/core/Slider';

function PaperComponent(props) {
    return (
      <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
}
  
function Settings(props){
    const [state, setState] = React.useState({
        captions: props.captions,
        labels: props.labels,
        hszValue: props.hszRange,
    });

    function handleCancel(){
        props.toggleSettings();
    }
 
    function handleSubmit(){
        props.applySettings(state.captions, state.labels, state.hszValue);
    }
 
    const handleChange = name => event => {
        setState({ ...state, [name]: event.target.checked });
    };

    function handleSliderChange(e, val){
        setState({...state, hszValue: val});
    }

    return (
        <div>
        <Dialog open={props.open} disableBackdropClick={true}
            onClose={handleCancel} PaperComponent={PaperComponent} >
            <DialogTitle 
                style={{ 
                    cursor: 'move', 
                    borderBottom: '1px groove lightgray' 
                }} id="draggable-dialog-title"><b>Settings</b>
            </DialogTitle>
            <DialogContent>
            <Fade in={state.loading} unmountOnExit style={{
                transitionDelay: state.loading ? '800ms' : '0ms',
            }}>
                <LinearProgress />
            </Fade>
            
            <div className="panelcontent" >
                <div className="biodiversitycontent" >
                    <div>Show quadrant labels</div>
                    <div>
                        <Switch 
                            checked={state.captions} 
                            onChange={handleChange('captions')} 
                            value="captions"
                            color="primary"
                        />
                    </div>
                </div>
                <div className="biodiversitycontent" >
                <div>Show Patch IDs</div>
                    <div>
                        <Switch 
                            checked={state.labels} 
                            onChange={handleChange('labels')} 
                            value="labels"
                            color="primary"
                        />
                    </div>
                </div>
                <div className="biodiversitycontent" key="hszlabel" style={{textAlign:'center', fontWeight:'italic'}}>
                    <div>Filter patches by HSZ Value</div>
                </div>
                <div className="biodiversitycontent"  style={{paddingTop: '40px', paddingLeft:'20px', paddingRight:'20px'}} key="hszcontrol">
                    <Slider valueLabelDisplay="on" onChange={handleSliderChange} value={state.hszValue} min={0} max={1} step={0.1} />
                </div>
            </div>
            </DialogContent>
           <DialogActions>
               <button className="bbutton" onClick={handleCancel} > Close </button>
                <button className="bbutton" onClick={handleSubmit} style={{
                        color:'white', 
                        backgroundColor:'rgb(74, 119, 229)',
                        width:'auto'
                    }} > Apply </button>
            </DialogActions>
        </Dialog>
        </div>
    )
}

export default Settings;