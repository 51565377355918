
import {API} from 'aws-amplify';

export const getCVCA  = (params) => {
    return (dispatch, getState) => {
        API.get('cvcaapi', '/cvca/?year=2019')
        .then(data => {
            dispatch({type : "GET_CVCA_DATA", data})
        })
        .catch((err) => {
            console.log(" ERROR "+err);
            console.log("Will retry after 5 seconds")
                setTimeout(function(){ 
                    console.log("Retrying")
                    API.get('cvcaapi', '/cvca/?year=2019')
                    .then(data => {
                        dispatch({type : "GET_CVCA_DATA", data})
                    })
                    .catch((err) => {
                        console.log(" ERROR in retry "+err);
                        dispatch({type:'GET_CVCA_DATA_ERROR', err})
                    })
                }, 5000);
            //dispatch({type:'GET_CVCA_DATA_ERROR', err})
        });
    }
}