// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:f7e4cf58-01de-4494-a6db-8d6405262084",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_mDqLtBiRC",
    "aws_user_pools_web_client_id": "6vknv4pln94vad1pf658ks23fk",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://fywunr5mp3.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "newregionsapi",
            "endpoint": "https://m57b4olwyg.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "biodiversityapi",
            "endpoint": "https://7htxodmpm4.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "regionsapi",
            "endpoint": "https://hgv8er6qvd.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "spatialapi",
            "endpoint": "https://fdsk3uj59g.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "ecosystemsapi",
            "endpoint": "https://mav7cptuaj.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "speciesapi",
            "endpoint": "https://bo0jsfr6me.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "patchapi",
            "endpoint": "https://2nzidc32gl.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "imageapi",
            "endpoint": "https://3tj7m28pt3.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "frameworkapi",
            "endpoint": "https://0avdbp3sid.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "cvcaapi",
            "endpoint": "https://suwmqojybd.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "raapi",
            "endpoint": "https://bws1y6rlef.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        },
        {
            "name": "bugapi",
            "endpoint": "https://dq4vpl59mf.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "esa-store151913-production",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "esa-20200316004130-hostingbucket-production",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d3f62xc4cujkce.cloudfront.net"
};


export default awsmobile;
