import React from 'react';
import {Component} from 'react';
import SimplePanel from './SimplePanel';
import Skeleton from '@material-ui/lab/Skeleton';
import { getCVCA } from '../store/Actions/GetCVCAAction';
import { getRA } from '../store/Actions/GetRAAction';
import { connect } from 'react-redux';
import AddCVCA from './AddCVCA';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import {getCVCASunburst} from './Utils'; 
import ReactEchartsCore from 'echarts-for-react/lib/core';
import 'echarts/lib/chart/sunburst'
import echarts from 'echarts/lib/echarts';
import code from '../colorcoding.png';
//import UpIcon from '@material-ui/icons/SubdirectoryArrowLeft'
import {isEmpty} from './Utils';
//import RefreshIcon from '@material-ui/icons/LoopOutlined';


class CVCADetail extends Component {
    constructor(props){
      super(props);
      this.state = {formDisplay: false};

      this.showForm = this.showForm.bind(this);
      this.hideForm = this.hideForm.bind(this);
      //this.refresh = this.refresh.bind(this);
    }

    handleClick(selectedCVCA){
        this.props.loading(true);
        this.props.selectRA(null);

        if(selectedCVCA === null){
            this.props.getRA(null);
        }
        else{
            this.props.getRA({yearofstudy: 2019, cvca_id: selectedCVCA.id, cvca_name: selectedCVCA.name});
        }
    }

    handleClick1(selectedRA){
        if(this.props.selectedRA === undefined || this.props.selectedRA === null || this.props.selectedRA !== selectedRA){
            this.props.loading(true);
        }
        this.props.selectRA(selectedRA);
    }

    /*
    refresh(){
        if(this.props.cvca === undefined || this.props.cvca === null || isEmpty(this.props.cvca)){
            this.handleClick(null);
        }
        else{
            if(this.props.selectedRA === undefined || this.props.selectedRA === null){
                this.handleClick(this.props.cvca)
            }
            else{
                this.handleClick1(this.props.selectedRA);
            }
        }
    }
    */

    showForm(){
        this.setState({formDisplay: true});
    }

    hideForm(){
        this.setState({formDisplay: false});
    }

    render(){
        const editPermission = this.props.loggedIn.signInUserSession !== undefined ? 
        this.props.loggedIn.signInUserSession.accessToken.payload["cognito:groups"].includes("dataadmin")
        : false;

        const buttonbar = [];
        if(editPermission) {
            buttonbar.push(<AddIcon key="add" onClick={this.showForm} style={{color: 'rgb(201,108,56)', cursor: "pointer",paddingLeft: '10px', marginBottom:'-5px'}} /> );
        }
        //buttonbar.push(<RefreshIcon key="refresh" onClick={() => this.refresh()} style={{color: 'rgb(201,108,56)', cursor: "pointer",paddingLeft: '10px', marginBottom:'-5px'}}/>);

        const raList = this.props.cvca !== undefined && this.props.cvca !== null && this.props.cvca.framework !== undefined && this.props.cvca.framework !== null ?
                        this.props.cvca.framework.map(ra => {
                            return (
                                <button id={ra.id} key={ra.id} className="bbutton" 
                                style={this.props.selectedRA !== undefined && this.props.selectedRA !== null && this.props.selectedRA.id === ra.id?
                                    {color: 'white', backgroundColor: 'rgb(201,108,56)', border:'1px solid rgb(201,108,56)', fontWeight: 'bold' } :
                                    {color: 'rgb(201,108,56)', backgroundColor: 'white', border:'1px solid rgb(201,108,56)', fontWeight: 'bold' }
                                }
                                onClick={() => this.handleClick1(ra)}
                                >{ra.id}</button>
                            )
                        }) : null;

        const sunburstoptions = this.props.selectedRA !== undefined && this.props.selectedRA !== null ? {
            series: {
                type: 'sunburst', radius:['1%', '100%'], sort: null, startAngle:"165",
                data: getCVCASunburst(this.props.selectedRA),
                levels: [{}, {label:{ padding: 5, rotate: 0, fontSize: 16 }}, {label:{ rotate: 'tangential', fontSize: 16} }]
            }
        } : {} ;

        const cvcaList = this.props.cvcas.map((cvca) => {
            return (
                <button id={cvca.id} key={cvca.id} className="bbutton" 
                style={
                    this.props.cvca !== undefined && this.props.cvca !== null && this.props.cvca.id === cvca.id ? 
                    {backgroundColor: 'rgb(201,108,56)', color: 'white', border:'1px solid rgb(201,108,56)', fontWeight: 'bold' } :
                    {color: 'rgb(201,108,56)', backgroundColor: 'white', border:'1px solid rgb(201,108,56)', fontWeight: 'bold' }
                }
                onClick={() => this.handleClick(cvca)} >{cvca.name}</button>
            )
        });

        const breadcrumbBtns = [];
        if(this.props.cvca !== undefined && this.props.cvca !== null && !isEmpty(this.props.cvca)){
            breadcrumbBtns.push(<button id="india" key="india" className="breadcrumbbutton" 
            style={{color: 'rgb(201,108,56)', backgroundColor: 'white', fontWeight: 'bold', padding: 0, border: 0 }}
            onClick={() => this.handleClick(null)} >India</button>)

            if(this.props.selectedRA !== undefined && this.props.selectedRA !== null){
                breadcrumbBtns.push('> ', <button id={this.props.cvca.id} key={this.props.cvca.id} className="breadcrumbbutton" 
                style={{color: 'rgb(201,108,56)', backgroundColor: 'white', fontWeight: 'bold', padding: 0, border: 0 }}
                onClick={() => this.handleClick(this.props.cvca)} >{this.props.cvca.name}</button>)
            }
            else{
                breadcrumbBtns.push('> ',this.props.cvca.name);
            }
        }
        else{
            breadcrumbBtns.push('India');
        }

        const quadrant = this.props.selectedRA !== undefined && this.props.selectedRA !== null ?
                            "("+(this.props.selectedRA.resource_dependency < 50 ? "LD" : "HD")+
                            (this.props.selectedRA.governance_ability < 50 ? "LG" : "HG")+")"
        : "";

        return (
            <div className="ecosystemdetail" key="ecosystemdetail" >
                    <div className="stickyheader" > 
                        <SimplePanel title={breadcrumbBtns} 
                        addStyle={
                            this.state.selected !== null ?
                            {width:'98%', borderTop: '2px solid rgb(201,108,56)'} :
                            {width:'98%'} 
                        }
                        buttons={this.props.cvcas !== undefined && this.props.cvcas !== null && !isEmpty(this.props.cvcas) ? buttonbar : null}
                        >
                            <div className="regionpanelbuttonbar" style={{alignItems:'center'}}>
                                { raList !== null && raList.length > 0 ? raList : 
                                  cvcaList !== null && cvcaList.length > 0 ? cvcaList : 
                                  <Skeleton height={100} width="98%" animation="pulse" /> 
                                }
                            </div>
                        </SimplePanel>
                    </div>
                    { this.props.selectedRA !== undefined && this.props.selectedRA !== null ?
                        <SimplePanel key="indices" title={this.props.selectedRA.id + ' '+ quadrant } addStyle={{width:'98%', borderTop: '2px solid rgb(201,108,56)'}} 
                        addContentStyle={{
                            padding:'10px', 
                            backgroundImage: `url(${code})`, 
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: '10px 10px' 
                        }}>
                        <ReactEchartsCore 
                            echarts={echarts} 
                            style={{height: '60vh'}} 
                            option={sunburstoptions} 
                            plugins={[{
                                beforeInit: function (chart) {
                                  chart.data.labels.forEach(function (e, i, a) {
                                    if (/\n/.test(e)) {
                                      a[i] = e.split(/\n/)
                                    }
                                  })
                                }
                              }]}
                        />
                        </SimplePanel> : null }
                    
                    {  editPermission ? 
                    <AddCVCA 
                        open={this.state.formDisplay} 
                        close={this.hideForm} 
                        key={"addcvca"+(this.props.cvca !== undefined && this.props.cvca !== null && !isEmpty(this.props.cvca) ? this.props.cvca.id : "")} 
                        year={2019}
                        cvcas={this.props.cvcas} 
                        cvca={this.props.cvca !== undefined && this.props.cvca !== null && !isEmpty(this.props.cvca) ? this.props.cvca.id : ""}
                        selectedCVCA={this.state.selected}
                    />  : 
                    null 
                    }    
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
            getCVCA: (params) => dispatch(getCVCA(params)),
            getRA: (params) => dispatch(getRA(params)),
        }
  }   
   const mapStateToProps = state => {
      return {
        cvcas: state.getCVCA.CVCAList,
        cvca: state.getRA.CVCA
      }
    } 
  
  export default connect(mapStateToProps, mapDispatchToProps)(CVCADetail);