import React from 'react';
import SimplePanel from './SimplePanel'
import Slider from '@material-ui/core/Slider';
import {withStyles} from '@material-ui/styles';

export default function HealthPanel(props){
    const StyledSlider = withStyles({
        root: {
          color: props.fillcolor
        },
        disabled: {
            color: props.fillcolor
        },
      })(Slider);

    const contents = (typeof props.metrics !== 'undefined') ? props.metrics.map((metric, id) => {
        return (
            <div  key={id}>
                <div className="spatialmetric" style={{color:props.fillcolor}}>{metric.value}</div>
                { 
                    typeof metric.percent === 'undefined' ? null : 
                    <div><StyledSlider disabled defaultValue={metric.percent} /></div>
                }
                <div className="spatialtext">{metric.desc}</div>
            </div>
        )
    }) : '';

    return (
        <SimplePanel title="Ecosystem health" addStyle={{borderTop: '2px solid '+props.fillcolor}}>
            { contents === '' ? 
                <div className="spatialcontents" >
                    No data
                </div>
            :   <div className="healthcontents" >
                    {contents}
                </div>
            }
        </SimplePanel>
    )
}
