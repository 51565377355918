import React from 'react';
import MenuItem from './MenuItem';
import SimplePanel from './SimplePanel';

function MenuBar(props) {
    const ecosystemMenu = props.ecosystemTypes.map((ecosystemType) => {
        return (
            <MenuItem 
                img={ecosystemType.icon} 
                text={ecosystemType.name} 
                id={ecosystemType.id} key={ecosystemType.id}
                selected={props.ecoProfile !== undefined && props.ecoProfile.startsWith(ecosystemType.id)}
                loggedIn={props.loggedIn}
                showLogin={props.showLogin}
                loading={props.loading}
                showFullScreen={props.showFullScreen}
            />
        )
    }) 
    
    return (
        <SimplePanel 
            title="Ecosystems" 
            addStyle={{borderTop: '2px solid '+props.fillcolor, width:'98%'}}
            buttons={props.buttonbar}
        >
        <div className="spatialcontents">
            {ecosystemMenu}
        </div>
        </SimplePanel>          
    );
};

export default MenuBar;