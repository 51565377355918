const initialState = {
    Error: null,
    CVCAList: []
}

const GetCVCAReducer = (state = initialState, action) => {
    switch(action.type){
        case 'GET_CVCA_DATA':
            const newstate = {...state, Error:'', CVCAList: action.data};
            return newstate

        case 'GET_CVCA_DATA_ERROR':
            return {...state, Error: 'Fetch Failed'}

        default:
           return state  
    }
}

export default GetCVCAReducer