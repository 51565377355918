const initialState = {
    Error: null,
    EcoProfile: {}
}

const GetEcoReducer = (state = initialState, action) => {
    switch(action.type){
        case 'GET_DATA':
            const newstate = {...state, Error:'', EcoProfile: action.data[0]};
            return newstate

        case 'GET_DATA_ERROR':
            return {...state, Error: 'Fetch Failed'}

        default:
           return state  
    }
}

export default GetEcoReducer