import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';

import { connect } from 'react-redux'
import { signIn } from '../store/Actions/SignInAction'

import { Auth } from 'aws-amplify';
import { Hub } from 'aws-amplify'

import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';

function PaperComponent(props) {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

function Login(props) {
    const [state, setState] = React.useState({
        username : "",
        password : "",
        newpassword: "",
        loginError: "",
        loading: false
    })

    function handleKeyDown(e){
        if(e.keyCode === 13) {
            handleLogin();
        }
    }
    function handleKeyDownPwd(e){
        if(e.keyCode === 13) {
            changePassword();
        }
    }

    function handleChange(e){
        setState({
            ...state,
            [e.target.id] : e.target.value
        })
    }

    function handleCancel(){
        setState({...state, username : "", password: "", newpassword: "", loginError: ""})
        props.close();
    }

    function changePassword(){
        const loggedUser = Auth.completeNewPassword(props.user, state.newpassword);
        console.log(loggedUser);
    }

    function handleLogin(){
        props.signIn(state);
        setState({...state, loading: true, loginError:""});
    }

    React.useEffect(() => {
        Hub.listen('auth', (data) => {
          const { payload } = data
           if (payload.event === 'signIn') {
                setState({...state, username : "", password: "", newpassword: "", loading: false})
                props.close();
           }
           else if(payload.event === 'signIn_failure'){
            setState({...state, username : "", password: "", newpassword: "",
            loginError: "Invalid Email Address & Password combination. Please try again.",
            loading: false})
           }
        })
      }, [props, state])

    const newp = typeof props.challenge !== 'undefined' && 
                    props.challenge === "NEW_PASSWORD_REQUIRED" ;

    const label = newp ? 'Change Password' : 'Login';

    return (

        <div>
        <Dialog open={props.open} disableBackdropClick={true}
            onClose={handleCancel} PaperComponent={PaperComponent} >
            <DialogTitle 
                style={{ 
                    cursor: 'move', 
                    borderBottom: '1px groove lightgray' 
                }} id="draggable-dialog-title"><b>{label}</b>
            </DialogTitle>
            <DialogContent>
            <Fade in={!newp && state.loading} unmountOnExit style={{
                transitionDelay: state.loading ? '800ms' : '0ms',
            }}>
                <LinearProgress />
            </Fade>
            <DialogContentText>{state.loginError}</DialogContentText> 
                <form> 
                {newp ? 
                <TextField fullWidth id="newpassword" label="New Password" type="password"                
                autoComplete="new-password" onChange={handleChange} value={state.newpassword} required 
                onKeyDown={handleKeyDownPwd}/>
                : 
                [<TextField autoFocus key="username" fullWidth id="username" label="Email Address" type="email" 
                    autoComplete="username" onChange={handleChange} value={state.username} required/>,
                <TextField fullWidth key="password" id="password" label="Password" type="password"                
                    autoComplete="current-password" onChange={handleChange} value={state.password} 
                    onKeyDown={handleKeyDown} required />] 
                }
                </form>
            </DialogContent>
            <DialogActions>
                <button className="bbutton" onClick={handleCancel} > Cancel </button>
                <button 
                    className="bbutton" 
                    onClick={newp ? changePassword : handleLogin} 
                    style={{
                        color:'white', 
                        backgroundColor:'rgb(74, 119, 229)',
                        width:'auto'
                    }}
                > {label} </button>
            </DialogActions>
        </Dialog>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
            signIn: (params) => dispatch(signIn(params)),
        }
}    

const mapStateToProps = state => {
    return {
        user: state.signIn.user,
        challenge: state.signIn.user.challengeName
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(Login);